/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Basic
import React, { useRef, useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

// UI, images, styles and icons
import '../../../App.css';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { IoReturnUpBack } from 'react-icons/io5';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import { Alert } from '@material-ui/lab';
import './styles.css';
import titleHelper from '../../../helpers/titleHelper';
import SaveButton from '../../../components/Buttons/SaveButton';
import BackButton from '../../../components/Buttons/BackButton';
// API
import FockinkAPI from '../../../middlewares/Api';
import { useAuth } from '../../../hooks/account';

// Random image
// const currentSlide = Math.floor(Math.random() * 2) + 1;

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default () => {
  if (!localStorage.getItem('rememberMyUser')) {
    localStorage.setItem(
      'rememberMyUser',
      JSON.stringify({ login: '', password: '' }),
    );
  }
  // States
  const [lastRelease, setLastRelease] = useState({});
  const [iconData, setIconData] = useState(undefined);
  const [loginMethod, setLoginMethod] = useState(undefined);
  const [redirect, setRedirect] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const auxRemember = localStorage.getItem('rememberMe') === 'true' || false;
  const [remember, setRemember] = useState(auxRemember);
  const [credentials, setCredentials] = useState(() => {
    const localCredentials = localStorage.getItem('rememberMyUser');

    if (localCredentials && isJsonString(localCredentials)) {
      const parsed = JSON.parse(localCredentials);
      if (typeof parsed === 'object') {
        return { login: parsed.login, password: '' };
      }
    }
    return { login: '', password: '' };
  });

  const date = new Date();
  const year = date.getFullYear();
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const [isDesktop, setDesktop] = useState(false);
  const { signIn } = useAuth();

  const [sent, setSent] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState({ email: '' });
  const MySwal = withReactContent(Swal);

  const save = () => {
    setIsSaving(true);
    const sendData = {
      login: data.email,
    };
    FockinkAPI.post('/login/forgotPassword', sendData)
      .then(() => {
        setIsSaving(false);
        setSent(true);
      })
      .catch((e) => {
        setIsSaving(false);
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: e.response.data.reason || e.response.data.message,
        });
      });
  };

  // Lifecycle methods
  useEffect(() => {
    titleHelper('Login');
    FockinkAPI.get('/releaseNotes', {
      params: { isAdminPortal: 0 },
    }).then((res) => setLastRelease(res.data.data[0]));
    const urlParams = new URLSearchParams(window.location.search);
    const reason = urlParams.get('reason');

    if (window.innerWidth > 959 && window.innerHeight > 770) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }

    const updateMedia = () => {
      if (window.innerWidth > 959 && window.innerHeight > 770) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };

    if (reason) {
      let message = '';
      switch (reason) {
        case '401':
          message = 'Sua sessão expirou - Entre novamente';
          break;
        default:
          message = 'Algo de errado aconteceu :/ Entre novamente';
      }
      setErrorMessage(message);
    }

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  useEffect(() => {
    const userAlreadyLogged = localStorage[process.env.REACT_APP_STORAGE_KEY];
    if (userAlreadyLogged) {
      setRedirect(!redirect);
    }
    FockinkAPI.get('login/checkMethod')
      .then((response) => {
        setLoginMethod(response.data.data.type);

        if (response.data.data.accountId && response.data.data.organizationId) {
          setIconData(
            `${process.env.REACT_APP_FILESTORAGE_API}/organizations/${
              response.data.data.organizationId
            }/accounts/${response.data.data.accountId}/logo.jpg?${Math.floor(
              Math.random() * 1000000000,
            )}` || '/images/IoT Fockink/Ativo 3@3x.png',
          );
        }

        if (response.data.data.type === 'simpleAzureADApp') {
          window.location.href = response.data.data.url;
        }
      })
      .catch(() => <Redirect to="/404" />);
  }, []);

  // Handle Methods
  const handleChange = (event) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  const handleChangeForgot = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const email = emailInputRef.current.value;
      const password = passwordInputRef.current.value;

      if (!email || !password) {
        setErrorMessage('Por favor, insira suas credenciais');
        return;
      }
      const success = await signIn(email, password);
      if (success) {
        setRedirect(true);

        if (remember) {
          localStorage.setItem(
            'rememberMyUser',
            JSON.stringify({ login: credentials.login }),
          );
        }
      } else {
        setErrorMessage(
          'Erro ao processar requisição. Tente novamente mais tarde',
        );
      }
    } catch (error) {
      switch (error.response.status) {
        case 400: // Bad request
          setErrorMessage(error.response.data.message);
          break;

        case 401:
          setErrorMessage('Login e/ou Senha inválidos');
          break;

        case 404: // Not Found
          setErrorMessage('Login e/ou Senha inválidos');
          break;

        default:
          setErrorMessage(
            error?.response?.data?.message ||
              'Erro ao processar requisição. Tente novamente mais tarde',
          );
      }
    }
  };

  // eslint-disable-next-line no-restricted-globals
  const { from } = location.state || {
    from: { pathname: localStorage.getItem('sessionLastPage') || '/' },
  };
  if (redirect) return <Redirect to={from} />;
  // if (forgot) return <Redirect to="/forgotPassword" />;

  const history = useHistory();

  return (
    <div className="loginPage">
      {!forgot ? (
        <div className="form">
          {loginMethod === undefined && <CircularProgress size={100} />}
          {loginMethod === 'local' && (
            <div className="form-content">
              {/* <div className="logoHolder"> */}
              {iconData && (
                <img
                  src={iconData}
                  alt="logo"
                  className="sublogo"
                  onError={(e) => {
                    e.target.style.display = 'none';
                  }}
                />
              )}

              <img
                src="/images/IoT Fockink/Ativo 12@3x.png"
                alt="Logo"
                className="logo"
              />

              <Box mt={6} mb={3}>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  method="post"
                >
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      htmlFor="login"
                      inputRef={emailInputRef}
                      variant="outlined"
                      id="login"
                      name="login"
                      label="Login"
                      type="email"
                      value={credentials.login}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  </FormControl>
                  <br />
                  <br />
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      htmlFor="password"
                      inputRef={passwordInputRef}
                      variant="outlined"
                      id="password"
                      name="password"
                      label="Senha"
                      type={showPassword ? 'text' : 'password'}
                      value={credentials.password}
                      onChange={handleChange}
                      autoComplete="nope"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <MdVisibility />
                              ) : (
                                <MdVisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </FormControl>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{ color: '#979797', width: '67%', fontSize: 10 }}
                    >
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value={remember}
                            control={
                              <Checkbox checked={remember} color="primary" />
                            }
                            onChange={() => {
                              const aux = !remember;
                              setRemember(aux);
                              localStorage.setItem('rememberMe', aux);
                            }}
                            label="Relembre-me"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                    <div
                      className="forgotPassword"
                      onClick={() => setForgot(true)}
                      style={{ marginTop: 10, fontSize: 16 }}
                    >
                      <span>Esqueceu sua senha?</span>
                    </div>
                  </div>
                  <br />
                  <div className="to-right">
                    <Button variant="contained" color="primary" type="submit">
                      Entrar
                    </Button>
                  </div>
                  <img
                    style={{
                      width: '70px',
                      position: 'absolute',
                      zIndex: '9',
                      right: '10px',
                      bottom: '10px',
                    }}
                    src="/images/logoFockink.svg"
                    alt=""
                    className="logoFockink"
                  />
                </form>
              </Box>
              {errorMessage && (
                <Alert severity="warning" mt={1}>
                  {errorMessage}
                </Alert>
              )}
              {lastRelease && (
                <Button
                  variant="text"
                  disableRipple
                  sx={{
                    color: '#979797',
                    width: 'auto',
                    fontSize: '0.7em',
                    position: 'absolute',
                    left: '0',
                    bottom: '0px',
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => history.push('/releases')}
                >
                  {`Portal IOT versão ${lastRelease && lastRelease.version}`}
                </Button>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="form">
          <img
            src="/images/IoT Fockink/Ativo 12@3x.png"
            alt="Logo"
            className="logo"
          />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {sent && (
                <FormControl>
                  <h2>Recuperação de senha iniciada!</h2>
                  <h3>
                    Se
                    <i>{` ${data.email} `}</i>
                    estiver cadastrado na plataforma, você receberá um email com
                    o link para continuar a recuperação.
                  </h3>
                  <br />
                  <div className="to-right">
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        setForgot(false);
                        setSent(false);
                        setData({ email: '' });
                      }}
                      startIcon={<IoReturnUpBack />}
                    >
                      Voltar ao Login
                    </Button>
                  </div>
                </FormControl>
              )}

              {!sent && (
                <FormControl className="recover-form">
                  <h4>Infome sua credencial</h4>
                  <TextField
                    variant="outlined"
                    name="email"
                    onChange={handleChangeForgot}
                    value={data.email || ''}
                    label="Email"
                    type="email"
                    fullWidth
                  />
                  <br />

                  {sent ? (
                    <BackButton />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        gap: 10,
                      }}
                    >
                      <SaveButton
                        save={save}
                        notComplete={!data.email}
                        isSaving={isSaving}
                        customSaveText="Enviar"
                        style={{ width: 'max-content' }}
                      />
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => {
                          setForgot(false);
                          setSent(false);
                          setData({ email: '' });
                        }}
                        startIcon={<IoReturnUpBack />}
                      >
                        Retornar ao Login
                      </Button>
                    </div>
                  )}
                </FormControl>
              )}
              <br />
              <br />
              <FormControl />
              <img
                style={{
                  width: '100px',
                  position: 'absolute',
                  zIndex: '9',
                  right: '10px',
                  bottom: '10px',
                }}
                src="/images/logoFockink.svg"
                alt=""
                className="logoFockink"
              />
            </Grid>
          </Grid>
        </div>
      )}
      {isDesktop && (
        <a href="https://www.fockink.ind.br" style={{ marginTop: '15px' }}>
          <span style={{ color: 'white', cursor: 'pointer' }}>
            {`Copyright © ${year} Fockink Indústrias Elétricas`}
          </span>
        </a>
      )}
    </div>
  );
};
