const { detect } = require('detect-browser');

const browser = detect();

// const webview = () =>
//   browser.name === 'chromium-webview' || browser.os === 'Android OS';
// const chrome = () =>
//   browser.name === 'chrome' && parseInt(browser.version, 10) >= 87;
// const firefox = () =>
//   browser.name === 'firefox' && parseInt(browser.version, 10) >= 80;
// const edge = () =>
//   browser.name.startsWith('edge') && parseInt(browser.version, 10) >= 87;
// const safari = () =>
//   (browser.name === 'safari' || browser.name === 'ios') &&
//   parseInt(browser.version, 10) >= 13;

const isSupported = () => {
  if (browser) {
    // apagado os conssoles
  }
  // return
  return {
    // is: chrome() || firefox() || safari() || edge() || webview(),
    is: true,
    current: `${browser.name} (${browser.version}) ${browser.os}`,
  };
};

export { isSupported };
