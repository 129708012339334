import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Chip } from '@material-ui/core';
import { ImCross, ImBlocked, ImCheckmark } from 'react-icons/im';
import OcultableField from './Fields/OcultableField';
import colors from '../styles/colors';

export default (props) => {
  const [pwCheck, setPwCheck] = React.useState({
    length: false, upper: false, lower: false, numb: false, esp: false, isAllDone: false,
  });
  const [diff, setDiff] = React.useState(undefined);

  React.useEffect(() => {
    props.setData({
      pw: '', checkPw: '', currentPw: '', ready: false, accountId: localStorage.accountId || '',
    });
    // setLoading(false);
  }, []);

  const handleChange = (event) => {
    let { isAllDone } = pwCheck;
    let current = !props.currentPw;
    const dt = { ...props.data, [event.target.name]: event.target.value };
    if (event.target.name === 'pw') {
      const length = dt.pw.length > 7 && dt.pw.length < 33;
      const upper = !!dt.pw.match(/[A-Z]/);
      const lower = !!dt.pw.match(/[a-z]/);
      const numb = !!dt.pw.match(/[0-9]/);
      const esp = !!dt.pw.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);
      setPwCheck({
        length, upper, lower, numb, esp, isAllDone: length && upper && lower && numb && esp,
      });
      isAllDone = length && upper && lower && numb && esp;
    }

    if (props.currentPw) {
      current = !!dt.currentPw;
    }

    if (dt.checkPw) {
      setDiff(dt.pw !== dt.checkPw);
    }
    const ready = isAllDone && dt.pw === dt.checkPw && current;
    props.setData({ ...dt, ready });
  };

  return (
    <>
      <Grid container spacing={3} lg={12} md={12} xs={12} style={{ justifyContent: 'left' }}>
        <Grid item lg={12} md={12} xs={12} style={{ paddingRight: 0 }}>
          {
            (
              props.currentPw
              &&
              (
                <OcultableField
                  name="currentPw"
                  handleChange={handleChange}
                  value={props.data ? props.data.currentPw || '' : ''}
                  label="Senha Atual"
                  style={{ marginBottom: '15px', padding: 0, width: '100%' }}
                />
              )
            )
          }
        </Grid>
        <Grid item lg={12} md={12} xs={12} style={{ paddingRight: 0 }}>
          <OcultableField
            name="pw"
            handleChange={handleChange}
            value={props.data ? props.data.pw || '' : ''}
            label="Nova Senha"
            fullWidth
            style={{ paddingRight: 0, width: '100%' }}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12} style={{ paddingRight: 0 }}>
          <OcultableField
            name="checkPw"
            handleChange={handleChange}
            value={props.data ? props.data.checkPw || '' : ''}
            label="Confirme a Senha"
            fullWidth
            style={{ paddingRight: 0, width: '100%' }}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12} style={{ paddingRight: 0, marginBottom: -10 }}>
          <Typography style={{ fontSize: 14, fontWeight: 'bold', padding: 5, margin: 5, color: '#000000' }}>
            Requisitos de Senha:
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} xs={12} style={{ paddingRight: 0, marginTop: -15 }}>
          {
            pwCheck.length ? (
              <Chip
                variant="outlined"
                size="small"
                icon={<ImCheckmark style={{ color: colors.success }} />}
                label="Entre 8 e 32 caracteres"
                style={{ color: colors.success, borderColor: colors.success, margin: 5 }}
              />
            )
              :
              (
                <Chip
                  variant="outlined"
                  size="small"
                  icon={<ImBlocked style={{ color: colors.grey }} />}
                  label="Entre 8 e 32 caracteres"
                  style={{ color: colors.grey, borderColor: colors.grey, margin: 5 }}
                />
              )
          }
          {
            pwCheck.upper ? (
              <Chip
                variant="outlined"
                size="small"
                icon={<ImCheckmark style={{ color: colors.success }} />}
                label="Ao menos uma MAIÚSCULA"
                style={{ color: colors.success, borderColor: colors.success, margin: 5 }}
              />
            )
              :
              (
                <Chip
                  variant="outlined"
                  size="small"
                  icon={<ImBlocked style={{ color: colors.grey }} />}
                  label="Ao menos uma MAIÚSCULA"
                  style={{ color: colors.grey, borderColor: colors.grey, margin: 5 }}
                />
              )
          }
          {
            pwCheck.lower ? (
              <Chip
                variant="outlined"
                size="small"
                icon={<ImCheckmark style={{ color: colors.success }} />}
                label="Ao menos uma MINÚSCULA"
                style={{ color: colors.success, borderColor: colors.success, margin: 5 }}
              />
            )
              :
              (
                <Chip
                  variant="outlined"
                  size="small"
                  icon={<ImBlocked style={{ color: colors.grey }} />}
                  label="Ao menos uma MINÚSCULA"
                  style={{ color: colors.grey, borderColor: colors.grey, margin: 5 }}
                />
              )
          }
          {
            pwCheck.numb ? (
              <Chip
                variant="outlined"
                size="small"
                icon={<ImCheckmark style={{ color: colors.success }} />}
                label="Ao menos um NÚMERO"
                style={{ color: colors.success, borderColor: colors.success, margin: 5 }}
              />
            )
              :
              (
                <Chip
                  variant="outlined"
                  size="small"
                  icon={<ImBlocked style={{ color: colors.grey }} />}
                  label="Ao menos um NÚMERO"
                  style={{ color: colors.grey, borderColor: colors.grey, margin: 5 }}
                />
              )
          }
          {
            pwCheck.esp ? (
              <Chip
                variant="outlined"
                size="small"
                icon={<ImCheckmark style={{ color: colors.success }} />}
                label="Ao menos um CARACTERE ESPECIAL"
                style={{ color: colors.success, borderColor: colors.success, margin: 5 }}
              />
            )
              :
              (
                <Chip
                  variant="outlined"
                  size="small"
                  icon={<ImBlocked style={{ color: colors.grey }} />}
                  label="Ao menos um CARACTERE ESPECIAL"
                  style={{ color: colors.grey, borderColor: colors.grey, margin: 5 }}
                />
              )
          }
          {
            (((props.data.pw === '') &&
            (props.data.checkPw === '')) ||
            (props.data.checkPw === '')) ? (
              <Chip
                variant="outlined"
                size="small"
                icon={<ImBlocked style={{ color: colors.grey }} />}
                label="Nova Senha e Confirmação Iguais"
                style={{ color: colors.grey, borderColor: colors.grey, margin: 5 }}
              />
              )
              :
              ((diff !== undefined) && (diff)) ? (
                <Chip
                  variant="outlined"
                  size="small"
                  icon={<ImCross style={{ color: colors.error }} />}
                  label="Nova Senha e Confirmação Iguais"
                  style={{ color: colors.error, borderColor: colors.error, margin: 5 }}
                />
              )
                :
                (
                  <Chip
                    variant="outlined"
                    size="small"
                    icon={<ImCheckmark style={{ color: colors.success }} />}
                    label="Nova Senha e Confirmação são Iguais"
                    style={{ color: colors.success, borderColor: colors.success, margin: 5 }}
                  />
                )
          }
        </Grid>
      </Grid>
    </>
  );
};
