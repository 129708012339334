import React from 'react';
import { AuthProvider } from './account';
import { ThemeProviderHook } from './useTheme';
import { ThermometryReportProvider } from './thermometryReport';
import { AlarmProvider } from './AlarmsRule';
import { TechnicalDeliveryProviderHook } from './technicalDelivery';
import { TelemetryVariableProvider } from './telemetryVariables';
import { EquipamentProvider } from './equipament';
import { DashboardProvider } from './dashboard';
import PageProvider from './Contexts/Page';

import { TechnicalDeliveryThermomeryCentralProvider } from './TechnicalDeliveryForms';
import { SelectEquipamentModalProvider } from './SelectEquipamentModal';
import { SelectPlantModalProvider } from './SelectPlantModal';

export const AppProvider = ({ children }) => (
  <AuthProvider>
    <ThemeProviderHook>
      <ThermometryReportProvider>
        <TechnicalDeliveryProviderHook>
          <AlarmProvider>
            <TelemetryVariableProvider>
              <EquipamentProvider>
                <DashboardProvider>
                  <PageProvider>
                    <TechnicalDeliveryThermomeryCentralProvider>
                      <SelectEquipamentModalProvider>
                        <SelectPlantModalProvider>
                          {children}
                        </SelectPlantModalProvider>
                      </SelectEquipamentModalProvider>
                    </TechnicalDeliveryThermomeryCentralProvider>
                  </PageProvider>
                </DashboardProvider>
              </EquipamentProvider>
            </TelemetryVariableProvider>
          </AlarmProvider>
        </TechnicalDeliveryProviderHook>
      </ThermometryReportProvider>
    </ThemeProviderHook>
  </AuthProvider>
);
