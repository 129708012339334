import moment from 'moment';
import React from 'react';
import { handleMessageError } from '../helpers/handlerMessageError';
import FockinkAPI from '../middlewares/Api';

const DashboardContext = React.createContext(null);

export function DashboardProvider({ children }) {
  const [loading, setLoading] = React.useState(false);
  // const [dateLoading, setDateLoading] = React.useState(false);
  const [
    filteresReportResultData,
    setFilteredReportResultData,
  ] = React.useState();

  const [
    dontShowThermometryDashboard,
    setDontShowThermometryDashboard,
  ] = React.useState(false);

  const [dataMotorSummaryReport, setDataMotorSummaryReport] = React.useState();
  const [
    dataHotSensorSummaryReport,
    setDataHotSensorSummaryReport,
  ] = React.useState();

  const [data, setData] = React.useState({});
  const [dataMotorUnitChart, setDataMotorUnitChart] = React.useState();

  const [aerationChartLoadgin, setAerationChartLoading] = React.useState(false);

  const [
    formattedReportAerationStorage,
    setFormattedReportAerationStorage,
  ] = React.useState();

  const [
    levelTemperatureChartData,
    setLevelTemperatureChartData,
  ] = React.useState();
  const [
    loadingTemperatureChartData,
    setLoadingTemperatureChartData,
  ] = React.useState(false);

  const [plantIdToRequest, setPlantIdToRequest] = React.useState();
  const [selectedPlantName, setSelectedPlantName] = React.useState();

  function setPlantToRequestData(plantId, plantName) {
    setPlantIdToRequest(plantId);
    setSelectedPlantName(plantName);
  }

  function formatUnitStorageData(dataUnits) {
    const filterdReportMedia = dataUnits.map((key) => ({
      id: key.idUnit,
      name: key.nameUnit,
      subarea: key.sub,
      plantId: key.plantId,
      temperature_average: key.average,
      // mockado pq a API está entregando 0
      // temperature_average: Math.floor(Math.random() * (3 - 1 + 1)) + 1,
    }));

    const filterdReportLevel = dataUnits.map((key) => ({
      date: moment(key.ts).format('DD/MM/YYYY HH:mm:ss'),
      grain: key.grain,
      id: key.idUnit,
      name: key.nameUnit,
      storage: key.percLevel,
      subarea: key.sub,
      equipamentId: key.equipamentId,
    }));

    const filterdReport = dataUnits.map((key) => ({
      date: moment(key.ts).format('DD/MM/YYYY HH:mm:ss'),
      id: key.idUnit,
      name: key.nameUnit,
      subarea: key.sub,
    }));

    const concatenatedArrayDatesOfResult = [];
    const arrayDatesOfResult = dataUnits.map((key) => key.dates);

    for (let k = 0; k < arrayDatesOfResult.length; k += 1) {
      for (let u = 0; u < arrayDatesOfResult[k].length; u += 1) {
        concatenatedArrayDatesOfResult.push(arrayDatesOfResult[k][u]);
      }
    }

    const filteredResults = {
      media: filterdReportMedia,
      level: filterdReportLevel,
      report: filterdReport,
      levelReport: concatenatedArrayDatesOfResult,
    };

    setFormattedReportAerationStorage(filteredResults);
  }

  function handleFilterReportResults(dataMotors) {
    const filterdReportResult = dataMotors.map((key) => ({
      date: key.ts,
      hours_worked: key.aeration,
      id: key.idUnit,
      equipamentId: key.equipamentId,
      motors_on: key.qtyMotor,
      name: key.nameUnit,
      subarea: key.sub,
      haveAerationShared: key.haveAerationShared,
      failMotor: key.failMotor,
      plantId: key.plantId,
    }));

    return filterdReportResult;
  }

  const handleGetDataMotorUnitChart = async () => {
    try {
      setAerationChartLoading(true);
      const response = await FockinkAPI.get(
        '/dashboards/thermometry/sumup/aerationChart',
        {
          params: {
            plantId: plantIdToRequest,
          },
        },
      );
      setDataMotorUnitChart(response.data.data);
      setAerationChartLoading(false);
    } catch (error) {
      setAerationChartLoading(false);
      handleMessageError(error);
    }
  };

  function getDataMotorSummaryReport(dataMotorsOnOff) {
    const filterdReportResult = [
      dataMotorsOnOff.motorOff,
      dataMotorsOnOff.motorOn,
    ];

    setDataMotorSummaryReport(filterdReportResult);
  }

  function getSummarySensroReport(dataSensorHot) {
    const filterdReportResult = [
      dataSensorHot.hotSensor,
      dataSensorHot.coldSensor,
    ];
    setDataHotSensorSummaryReport(filterdReportResult);
  }

  const handleGetMainData = async () => {
    try {
      setLoading(true);
      const response = await FockinkAPI.get(
        '/dashboards/thermometry/sumup/main',
        {
          params: {
            plantId: plantIdToRequest,
          },
        },
      );

      if (response.data.data.noValidPlants) {
        setDontShowThermometryDashboard(true);
        return;
      }

      const responseDates = await FockinkAPI.get(
        '/dashboards/thermometry/sumup/levelTempChart',
        {
          params: {
            plantId: plantIdToRequest,
          },
        },
      );

      const newReportState = response.data.data.dataUnits.map((dataUnit) => {
        const unit = responseDates.data.data.find(
          (date) =>
            date.sub === dataUnit.sub && date.nameUnit === dataUnit.nameUnit,
        );

        if (unit) {
          dataUnit.dates = unit.dates;
        } else {
          dataUnit.dates = [];
        }

        return dataUnit;
      });

      const dt = { ...response.data.data, dataUnits: newReportState };
      const {
        dataSensorHot,
        dataMotorsOnOff,
        dataMotorsUnit,
      } = response.data.data;

      const filteredReportsResult = handleFilterReportResults(dataMotorsUnit);
      setFilteredReportResultData(filteredReportsResult);
      getDataMotorSummaryReport(dataMotorsOnOff);
      formatUnitStorageData(newReportState);
      getSummarySensroReport(dataSensorHot);
      setData(dt);
      setLoading(false);
    } catch (error) {
      handleMessageError(error);
      setLoading(false);
    }
  };

  async function handleGetLevelTemperatureChartData() {
    try {
      setLoadingTemperatureChartData(true);
      const response = await FockinkAPI.get(
        '/dashboards/thermometry/sumup/levelTempChart',
        {
          params: {
            plantId: plantIdToRequest,
          },
        },
      );
      setLevelTemperatureChartData(response.data.data);
      setLoadingTemperatureChartData(false);
    } catch (error) {
      setLoadingTemperatureChartData(false);
      handleMessageError(error);
    }
  }

  /*   React.useEffect(() => {
    (async () => {
      const profile = localStorage.getItem('profile');

      if (profile && profile === 'clientadmin') {
        await handleGetMainData();
        await handleGetDataMotorUnitChart();
        await handleGetLevelTemperatureChartData();
      }
    })();
  }, [plantIdToRequest]); */

  return (
    <DashboardContext.Provider
      value={{
        data,
        loading,
        // dateLoading,
        dataMotorUnitChart,
        dataMotorSummaryReport,
        filteresReportResultData,
        dataHotSensorSummaryReport,
        formattedReportAerationStorage,
        levelTemperatureChartData,
        loadingTemperatureChartData,
        aerationChartLoadgin,
        handleGetMainData,
        handleGetDataMotorUnitChart,
        handleGetLevelTemperatureChartData,
        setPlantIdToRequest,
        setPlantToRequestData,
        selectedPlantName,
        dontShowThermometryDashboard,
        plantIdToRequest,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboardContext() {
  const context = React.useContext(DashboardContext);
  if (!context) {
    throw new Error('DashboardContext must be used inside context APP');
  }

  return context;
}
