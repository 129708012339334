import { React, createContext, useContext, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import FockinkAPI from '../middlewares/Api';

const selectEquipamentModalContext = createContext({});

const SelectEquipamentModalProvider = ({ children }) => {
  const [equipaments, setEquipaments] = useState([]);
  const [open, setOpen] = useState(false);

  const addEquipament = useCallback(
    (newEquip) => {
      setEquipaments((oldState) => [...oldState, newEquip]);
    },
    [equipaments],
  );

  const directSet = useCallback(
    (newList) => {
      setEquipaments(newList);
    },
    [equipaments],
  );

  const removeEquipament = useCallback(
    (equipId) => {
      setEquipaments((oldState) =>
        oldState.filter((equips) => equips.id !== equipId));
    },
    [equipaments],
  );

  const handleModal = (newVal) => {
    if (newVal && typeof newVal === 'boolean') {
      setOpen(newVal);
    }

    setOpen((oldState) => !oldState);
  };

  const fetchEquipaments = useCallback(async () => {
    try {
      const accounts = FockinkAPI.get('/accounts');

      const equips = FockinkAPI.get('/equipaments');
      const plants = FockinkAPI.get('/plants', {
        params: { extended: true },
      });

      const results = await Promise.allSettled([accounts, equips, plants]);
      const status = results.every((result) => result.status === 'fulfilled');

      if (!status) {
        toast.error('Falha na busca pelos dados');
      }

      const onlyUnits = results[1].value.data.data
        .map((equipament) => equipament?.unit)
        .filter((each) => each !== undefined && each.id);
      const uniqueUnits = new Map(onlyUnits.map((pos) => [pos.id, pos]));

      const returnData = {
        account: results[0].value.data.data,
        equipaments: results[1].value.data.data,
        plants: results[2].value.data.data,
        units: [...uniqueUnits.values()],
      };

      return { success: true, data: returnData };
    } catch (error) {
      toast.error(error.message);
      return { success: false, data: {} };
    }
  }, [equipaments]);

  return (
    <selectEquipamentModalContext.Provider
      value={{
        equipaments,
        open,
        addEquipament,
        removeEquipament,
        handleModal,
        fetchEquipaments,
        directSet,
      }}
    >
      {children}
    </selectEquipamentModalContext.Provider>
  );
};

const useSelectEquipamentModal = () => {
  const context = useContext(selectEquipamentModalContext);

  if (!context) {
    throw new Error(
      'useSelectEquipamentModal must be within an SelectEquipamentModalProvider',
    );
  }

  return context;
};

export { useSelectEquipamentModal, SelectEquipamentModalProvider };
