import { toast } from 'react-toastify';

export function handleMessageError(error) {
  if (error.request) {
    const errorMessage = JSON.parse(error.request.response).message;
    if (errorMessage === 'Validation failed') {
      return toast.error(
        JSON.parse(error.request.response).validation.body.message,
      );
    }
    return toast.error(errorMessage);
  }
  return toast.error(error);
}
