import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import NavbarAndRouter from './Router/NavbarAndRouter';
import { AppProvider } from './hooks';
import 'react-toastify/dist/ReactToastify.css';

import theme from './styles/themes/theme';

export function App() {
  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <NavbarAndRouter />
          <div style={{ position: 'relative' }}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </AppProvider>
  );
}
