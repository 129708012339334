import { format } from 'date-fns-tz';
import moment from 'moment';

const currency = (value, maximumDecimals) =>
  new Intl.NumberFormat(localStorage.localeTag, {
    style: 'currency',
    currency: localStorage.localeCurrency,
    maximumSignificantDigits: maximumDecimals,
  }).format(value);

const number = (value, fracDig, minimum = 0) => {
  const numb = new Intl.NumberFormat(localStorage.localeTag, {
    ...(minimum ? { minimumFractionDigits: minimum } : {}),
    maximumFractionDigits: fracDig === undefined ? 2 : fracDig,
  }).format(value);

  if (numb === 'NaN') {
    return '-';
  }

  return numb;
};

const formatDate = (date, customFormat) => {
  if (date) {
    let dt = date;

    if (typeof date === 'string' && date.match(/\d{2}\/\d{2}\/\d{4}/)) {
      dt = date.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$3-$2-$1');
    }
    if (typeof date === 'string') {
      dt = moment(dt).toDate();
    }
    if (typeof date === 'number') {
      dt = moment.unix(dt).toDate();
    }
    try {
      return format(dt, customFormat || 'dd/MM/yyyy HH:mm', {
        timeZone: localStorage.getItem('tz') || 'America/Sao_Paulo',
      });
    } catch (e) {
      return date;
    }
  }

  return '';
};

// const dateDiff = (value) => {
//   return new Intl.RelativeTimeFormat(localStorage.localeTag).format(value);
// };

// eslint-disable-next-line consistent-return
const dateDiff = (date) => {
  const formatter = new Intl.RelativeTimeFormat(localStorage.localeTag, {
    numeric: 'auto',
  });

  const DIVISIONS = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' },
  ];
  let duration = (date - new Date()) / 1000;

  for (let i = 0; i <= DIVISIONS.length; i += 1) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
};

const formatUnits = (d, defaultUnit, defaultNumber) => {
  if (typeof d === 'string') {
    let p = false;
    let rem = false;
    let rm = false;
    let em = false;
    let pct = false;
    let data;
    let unity;
    p = d.includes('px');
    rem = d.includes('rem');
    rm = d.includes('rm');
    em = d.includes('em') && !d.includes('rem');
    pct = d.includes('%');
    if (p) {
      unity = 'px';
      data = d.replace('px', '');
    } else if (rem) {
      unity = 'rem';
      data = d.replace('rem', '');
    } else if (rm) {
      unity = 'rm';
      data = d.replace('rm', '');
    } else if (em) {
      unity = 'em';
      data = d.replace('em', '');
    } else if (pct) {
      unity = '%';
      data = d.replace('%', '');
    } else {
      unity = defaultUnit;
      data = d;
    }
    return {
      number: data,
      unit: unity,
    };
  }
  if (typeof d === 'number') {
    return {
      number: d,
      unit: defaultUnit,
    };
  }
  return {
    number: defaultNumber,
    unit: defaultUnit,
  };
};

const secondsToHm = (s) => {
  const seconds = Number(s);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const hDisplay = h + d > 0 ? `${h + d * 24}h` : '0h';
  const mDisplay = m > 0 ? `${m}m`.padStart(3, '0') : '00m';
  const ret = hDisplay.padStart(3, '0') + mDisplay.padStart(3, '0');
  return ret.trim() || '00m';
};

const secondsToDhms = (sec) => {
  const seconds = Number(sec);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? `${d}d `.padStart(4, '0') : '';
  const hDisplay = h > 0 ? `${h}h `.padStart(4, '0') : '';
  const mDisplay = m > 0 ? `${m}m `.padStart(4, '0') : '';
  const sDisplay = s > 0 ? `${s}s `.padStart(4, '0') : '';
  const ret = dDisplay + hDisplay + mDisplay + sDisplay;
  return ret.trim() || '00s';
};

export {
  number,
  currency,
  dateDiff,
  formatDate,
  formatUnits,
  secondsToHm,
  secondsToDhms,
};
