import React from 'react';
import { Breadcrumbs, Typography, Grid, Button, Skeleton } from '@mui/material';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { MdNavigateNext, MdAdd } from 'react-icons/md';
import titleHelper from '../helpers/titleHelper';

const classes = {
  pageHeader: {
    padding: '20px 0px 22px', // '12px 0px 22px'
  },
  breadcrumbStyle: {
    color: '#727272',
  },
  title: {
    margin: '10px 0',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '28px',
    letterSpacing: '0px',
    color: '#727272',
  },
  backButton: {
    background: 'transparent',
    border: '0',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    color: '#727272',
    transition: 'color 0.2s',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
    },
  },
};

export default (props) => {
  const [goToNew, setGoToNew] = React.useState(false);

  React.useEffect(() => {
    const title = props.pageTitle || props.title;
    if (title) {
      titleHelper(title);
    } else if ((props.routes || []).length > 0) {
      titleHelper(props.routes[props.routes.length - 1].name);
    }
  }, [props.pageTitle, props.title, props.routes]);

  React.useEffect(() => {
    setGoToNew(false);
  }, [goToNew]);

  if (goToNew && props.clientName) {
    return <Redirect push to={`${window.location.pathname}/new?client=${props.clientName}`} />;
  }
  if (goToNew) {
    return <Redirect push to={`${window.location.pathname}/new`} />;
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={classes.pageHeader}
    >
      <Grid item>
        <Breadcrumbs
          separator={<MdNavigateNext fontSize="small" />}
          aria-label="Breadcrumb"
        >
          <RouterLink
            color="textPrimary"
            to="/"
            style={{ textDecoration: 'none' }}
          >
            <Typography style={classes.breadcrumbStyle}>Início</Typography>
          </RouterLink>

          {props.loading ? (
            <Skeleton variant="text" width={210} animation="wave" />
          ) : (
            (props.routes || []).map((value, index) => {
              const last = index === props.routes.length - 1;
              if (last) {
                return (
                  <Typography style={classes.breadcrumbStyle} key={value.name}>
                    <strong>{value.name}</strong>
                  </Typography>
                );
              }
              if (!value.to) {
                return (
                  <Typography style={classes.breadcrumbStyle} key={value.name}>
                    {value.name}
                  </Typography>
                );
              }
              return (
                <RouterLink
                  color="textPrimary"
                  to={value.to}
                  key={value.name}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography style={classes.breadcrumbStyle} key={value.to}>
                    {value.name}
                  </Typography>
                </RouterLink>
              );
            })
          )}
        </Breadcrumbs>
        <p style={classes.title}>{props.title}</p>
        {/* {props.routes && (
          <RouterLink
            to={props.routes[0].to || '/'}
            style={classes.backButton}
          >
            <IoIosArrowBack
              style={{ marginLeft: '-5px', padding: '0' }}
              size={16}
              color="#727272"
            />
            voltar
          </RouterLink>
        )} */}
      </Grid>
      <Grid item>
        {/* props.newBtn */}
        { props.newBtn ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setGoToNew(true)}
            startIcon={<MdAdd />}
          >
            { props.newBtn }
          </Button>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};
