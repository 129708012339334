import React from 'react';
import { Box, CircularProgress, keyframes } from '@mui/material';
import Logo from '../../pages/Home/logoIot';

const Gradient = keyframes`
      0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const style = {
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 9999,
  display: 'flex',
  width: '100%',
  height: '100vh',
  color: '#fff',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(60deg, #133c8a, #1e68a5, #597fa7, #3386d4)',
  backgroundSize: '400% 400%',
  animation: `${Gradient} 15s ease infinite`,
  opacity: 0.8,
};

function Loading() {
  return (
    <Box style={style}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress style={{ color: 'white', width: 200, height: 200 }} />
      </Box>
      <div style={{ maxWidth: '400px', width: '90%' }}>
        <Logo width="100%" height={300} />
      </div>
    </Box>
  );
}

export default Loading;
