import React, { createContext, useContext } from 'react';
import FockinkAPI from '../middlewares/Api';
import { normalizeErrorData } from '../helpers/normalizeErrorData';
import { formatDate, number } from '../helpers/Intl';

const ThermometryReportContext = createContext([]);

export const ThermometryReportProvider = ({ children }) => {
  const getMonitoringReportDrillDown = async (
    selectedDate,
    selectedGrains,
    plantId,
  ) => {
    try {
      const response = await FockinkAPI.get(
        `reports/thermometry/monitoringReportDrillDown/${plantId}`,
        {
          params: {
            date: selectedDate,
            grains: selectedGrains,
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const lightenDarkenColor = (color, percent) => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100, 10);
    G = parseInt((G * (100 + percent)) / 100, 10);
    B = parseInt((B * (100 + percent)) / 100, 10);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

    return `#${RR}${GG}${BB}`;
  };

  const handleFormatReportParameters = (
    parameters,
    plant,
    daysInMonth,
    showMovingAvg,
  ) => {
    const formatValues = [];

    parameters.forEach((parameter) => {
      const label = {
        label: `Pontos entre ${parameter.limitMin} e ${parameter.limitMax} °C`,
        key: `${plant}-Pontos entre ${parameter.limitMin} e ${parameter.limitMax}`,
        color: parameter.color,
        legend: true,
      };
      const days = daysInMonth.map((day) => {
        if (parameter.datas && parameter.datas[day]) {
          const dataValue = normalizeErrorData(parameter.datas[day]);
          return {
            label: dataValue,
            key: `${plant}-${day}-parameters`,
          };
        }
        return { label: '--', key: day };
      });

      formatValues.push([label, ...days]);

      if (showMovingAvg) {
        const labelAvg = {
          label: `Média móvel dos pontos entre ${parameter.limitMin} e ${parameter.limitMax} °C`,
          key: `${plant}-Média móvel dos pontos entre ${parameter.limitMin} e ${parameter.limitMax}`,
          color: lightenDarkenColor(parameter.color, -10),
          legend: true,
        };
        const daysAvg = daysInMonth.map((day) => {
          if (parameter.movingAverage && parameter.movingAverage[day]) {
            const dataValue = normalizeErrorData(parameter.movingAverage[day]);
            return {
              label: number(dataValue),
              key: `${plant}-${day}-parameters`,
            };
          }
          return { label: '--', key: day };
        });

        formatValues.push([labelAvg, ...daysAvg]);
      }
    });

    return formatValues;
  };

  const handleFormatReportTotal = (total, plant, daysInMonth) => {
    const label = {
      label: 'Total de pontos quentes',
      key: `${plant}-total de pontos quentes`,
      legend: true,
    };
    const days = daysInMonth.map((day) => {
      if (total[day]) {
        const dataValue = normalizeErrorData(total[day]);
        return { label: dataValue, key: `${plant}-${day}-total` };
      }
      return { label: '--', key: `${plant}-${day}-total` };
    });

    return [label, ...days];
  };

  const handleFormatAeration = (aeration, plant, daysInMonth) => {
    if (aeration) {
      const label = {
        label: 'Total de aeração do dia (horas)',
        key: `${plant}-total de aeração dia (horas)`,
        color: aeration.color,
        legend: true,
      };
      const days = daysInMonth.map((day) => {
        if (aeration[day]) {
          // // formatDate(Date.now())
          const totalMinutes = Math.floor(aeration[day] / 60);

          const hours = Math.floor(totalMinutes / 60);
          const minutes = totalMinutes % 60;

          return {
            label: `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`,
            key: `${plant}-${day}-aeration`,
          };
        }
        return { label: '--', key: `${plant}-${day}-aeration` };
      });

      return [label, ...days];
    }
    return null;
  };

  const handleFormtTemperatueres = (temperatures, plant, daysInMonth) => {
    const label = {
      label: 'Temperatura externa (°C)',
      key: `${plant}-temperatura externa (°C)`,
      color: temperatures.color,
      legend: true,
    };
    const days = daysInMonth.map((day) => {
      if (temperatures[day]) {
        const datValue = normalizeErrorData(temperatures[day]);
        return {
          label: datValue,
          key: `${plant}-${day}-temperature`,
        };
      }
      return { label: '--', key: `${plant}-${day}-temperature` };
    });

    return [label, ...days];
  };

  const handleFormatHumidity = (humidity, plant, daysInMonth) => {
    const label = {
      label: 'Umidade relativa do ar (%)',
      key: `${plant}-umidade relativa do ar (%)`,
      color: humidity.color,
      legend: true,
    };
    const days = daysInMonth.map((day) => {
      if (humidity[day]) {
        const dataValue = normalizeErrorData(humidity[day]);
        return {
          label: dataValue,
          key: `${plant}-${day}-humidity`,
        };
      }
      return { label: '--', key: `${plant}-${day}-humidity` };
    });

    return [label, ...days];
  };

  const handleGetReportsThermometry = async (date, grains, plants) => {
    try {
      const { data } = await FockinkAPI.get(
        'reports/thermometry/monitoringReport',
        {
          params: {
            date,
            grains,
            plants,
          },
        },
      );
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  return (
    <ThermometryReportContext.Provider
      value={{
        handleGetReportsThermometry,
        getMonitoringReportDrillDown,
        handleFormatReportParameters,
        handleFormatReportTotal,
        handleFormatAeration,
        handleFormtTemperatueres,
        handleFormatHumidity,
      }}
    >
      {children}
    </ThermometryReportContext.Provider>
  );
};

export function useThermometryReport() {
  const context = useContext(ThermometryReportContext);
  if (!context) {
    throw new Error('useThermometryReport must be within an AuthProvider');
  }

  return context;
}
