import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  // ButtonGroup,
  Fade,
  Grid,
  IconButton,
  Modal,
  TextField,
  // InputAdornment,
} from '@mui/material';
import {
  RiCloseFill,
  // RiMailSendLine,
  RiSendPlaneFill,
} from 'react-icons/ri';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import jwtDecode from 'jwt-decode';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import { MdCloudUpload } from 'react-icons/md';
import { createFeedBack } from '../helpers/callApi';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // maxWidth: mobile ? '90%' : 600,
  // height: '80%',
  bgcolor: 'background.paper',
  backgroundColor: '#FFFFFF',
  border: '0px solid #000',
  boxShadow: 24,
  padding: 10,
  borderRadius: 5,
};

export default function ModalFeedBack({ openModal, closeModal }) {
  const [usuario, setUsuario] = useState('');
  const [feedBack, setFeedBack] = useState('');
  const [attachment, setAttachment] = useState('');
  const [filesAttachment, setFilesAttachment] = useState('');
  const MySwal = withReactContent(Swal);
  const token = localStorage.getItem('@FockinkAPP:token');

  useEffect(() => {
    if (token !== null) {
      const { login } = jwtDecode(token);

      setUsuario(login);
    }
  }, [token]);

  const handleClose = () => {
    closeModal(!openModal);
  };

  async function HandleClickSave() {
    const formData = new FormData();
    formData.append('uploadedFile', filesAttachment);
    formData.append('message', feedBack);
    formData.append('sourcePage', window.location.href);

    const feedbackCreated = await createFeedBack(
      formData,
    );

    // apresentar uma mensagem de sucesso ou de falha conforme a variavel feedbackCreated
    MySwal.fire({
      icon: feedbackCreated ? 'success' : 'warning',
      title: feedbackCreated ? 'Feedback enviado com Sucesso!' : 'Ops! Não conseguimos enviar seu feedback.',
      html: !feedbackCreated && `
        <div style="text-align: left">
        <br>
        <h3>Verifique os dados informados:</h3>
        <br>
        ${' '}
        <b>Comentário:</b> <br> ${feedBack}
        ${' '}
        <br><br>
        <b>Página Relacionada:</b> <br> ${window.location.href}
        </div>`,
    });

    setUsuario('');
    setFeedBack('');
    setAttachment('');
    setFilesAttachment('');

    handleClose();
  }

  return (
    <>
      <Modal
        // style={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div style={style}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginTop: 0, textAlign: 'right' }}>
                <IconButton onClick={handleClose} style={{ marginRight: -20 }}>
                  <RiCloseFill size={30} color="#556E7B" />
                </IconButton>
              </Grid>
              <Grid item xs={12} style={{ marginTop: -65 }}>
                <div>
                  <div>
                    <HiOutlineChatAlt2 size={30} color="#556E7B" />
                    <h2
                      style={{
                        color: '#556E7B',
                        marginTop: -33,
                        marginLeft: 33,
                      }}
                    >
                      Fale Conosco
                    </h2>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="user"
                  value={usuario}
                  label="Usuário"
                  InputProps={{ readOnly: true }}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="feedBack"
                  onChange={(e) => { setFeedBack(e.target.value); }}
                  value={feedBack}
                  label="Comentários"
                  multiline
                  minRows={10}
                  maxRows={10}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <input
                  accept="image/*"
                  // value={attachment}
                  onChange={(e) => {
                    setFilesAttachment(e.target.files[0]);
                    setAttachment(e.target.value);
                  }}
                  id="icon-button-file"
                  type="file"
                  style={{
                    display: 'none',
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="icon-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<MdCloudUpload size={22} />}
                    fullWidth
                    style={{ height: '100%' }}
                    size="small"
                  >
                    Upload
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12} lg={10}>
                <TextField
                  variant="outlined"
                  name="attachment"
                  value={attachment}
                  label="Anexo"
                  InputProps={{ readOnly: true }}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { HandleClickSave(); }}
                    startIcon={<RiSendPlaneFill />}
                  >
                    Enviar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>

  );
}
