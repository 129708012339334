import React from 'react';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import './styles.scss';
import { MenuItem/* , ListItemIcon, ListItemText */ } from '@mui/material';
// import { withStyles } from '@material-ui/core/styles';

// const StyledMenuItem = withStyles((theme) => ({
//   root: {
//     '&:focus': {
//       backgroundColor: theme.palette.primary.main,
//       '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
//         color: theme.palette.common.white,
//       },
//     },
//   },
// }))(MenuItem);

export function FullScreenButton({ color }) {
  const htmlElement = document.documentElement;
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  if (!color) {
    color = '#FFFFFF';
  }

  const openFullScreen = () => {
    if (!isFullScreen) {
      if (htmlElement.requestFullscreen) {
        htmlElement.requestFullscreen();
      } else if (htmlElement.webkitRequestFullscreen) {
        htmlElement.webkitRequestFullscreen();
      } else if (htmlElement.msRequestFullscreen) {
        htmlElement.msRequestFullscreen();
      } else {
        htmlElement.exitFullscreen();
      }

      setIsFullScreen(true);
      return;
    }
    document.exitFullscreen();
    setIsFullScreen(false);
  };

  return (
  // <Tooltip title={isFullScreen ? 'Fechar tela cheia' : 'Abrir em tela cheia'}>
    <MenuItem
      onClick={/* () => { */openFullScreen/* } */}
      style={{ marginLeft: -3 }}
    >
      <div>
        {
        isFullScreen ?
          <MdFullscreenExit color={color} size={25} />
          :
          <MdFullscreen color={color} size={25} />
        }
      </div>
      <div style={{ marginLeft: 10, marginTop: -5, color: '#0000008A' }}>
        Tela Cheia
      </div>
    </MenuItem>
  // </Tooltip>

  );
}
