import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Redirect, Link } from 'react-router-dom';
import '../../App.css';
import titleHelper from '../../helpers/titleHelper';

export default () => {
  React.useEffect(() => {
    titleHelper('403: Acesso não permitido');
  }, []);

  if (!localStorage.profile) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container spacing={1} style={{ padding: '24px', minHeight: 'calc(100vh - 101px)' }}>
      <Grid item xs={12}>
        <h2>Oops.. Você não está autorizado para acessar essa página.</h2>
        <h3>Se deseja acesso, por favor entrar em contato com o administrator.</h3>
        <Link to="/"> Voltar para Página Inicial </Link>
      </Grid>
    </Grid>
  );
};
