import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Skeleton,
  Tabs,
  Tab,
  Typography,
  Box,
  Select,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
  Chip,
} from '@mui/material';
import '../../../App.css';
import { Redirect } from 'react-router-dom';
import { SlWrench } from 'react-icons/sl';
import {
  RiLockPasswordLine,
  RiAccountCircleFill,
  RiMailFill,
  RiTimeFill,
  RiSeparator,
} from 'react-icons/ri';
import { SiOpslevel } from 'react-icons/si';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MdDashboard, MdOutlineFormatLineSpacing } from 'react-icons/md';
import FockinkAPI from '../../../middlewares/Api';
import SaveButton from '../../../components/Buttons/SaveButton';
import BackButton from '../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../components/Breadcrumbs';
import NewPasswordChecker from '../../../components/NewPasswordChecker';
import { useAuth } from '../../../hooks/account';
import ExternalApi from '../../../middlewares/ExternalApi';

const classes = {
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
    marginTop: '24px',
  },
  btnSpace2: {
    marginLeft: '15px',
    marginTop: '24px',
  },
  bottomButtons: {
    // marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
    textAlign: 'right',
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const csvSeparator = [
  {
    id: '0',
    separator: ',',
    description: 'Vírgula ( , ) ',
  },
  {
    id: '1',
    separator: ';',
    description: 'Ponto e Vírgula ( ; )',
  },
];

const csvQualificator = [
  {
    id: '0',
    separator: "'",
    description: 'Aspas Simples ' + '(' + " ' " + ')',
  },
  {
    id: '1',
    separator: '"',
    description: 'Aspas Duplas ' + '( " )',
  },
];

const tabTitle = [
  {
    id: '0',
    icon: <SlWrench size={15} />,
    title: 'PREFERÊNCIAS',
  },
  {
    id: '1',
    icon: <RiLockPasswordLine size={15} />,
    title: 'ALTERAR SENHA',
  },
];

export default () => {
  const [returnToMain, setReturnToMain] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [changePw, setChangePw] = React.useState(false);
  const [pwData, setPwData] = React.useState({ ready: false });
  const [isComplete, setIsComplete] = React.useState({ ready: false });
  const [isSaving, setIsSaving] = React.useState(false);
  const MySwal = withReactContent(Swal);

  const [timeZones, setTimeZones] = useState(['America/Sao_Paulo']);
  const [utc, setUtc] = useState('-03:00');
  const [dateTime, setDateTime] = useState('2022-03-17T15:32:03.786270-03:00');

  const [dashList, setDashList] = React.useState([
    { id: 'map', title: 'Mapa' },
    { id: 'thermometry', title: 'Painel de Termometria' },
    { id: 'general', title: 'Painel Geral' },
  ]);

  const { user } = useAuth();

  const [data, setData] = React.useState({
    name: '',
    tz: 'America/Sao_Paulo',
    csv: ';',
    csvString: '"',
    expandedLineQuantity: 5,
    modalLineQuantity: 5,
    pageLineQuantity: 10,
  });

  const [value, setValue] = useState(0);

  const handleChangeTabControl = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }

  function extraiHora(str) {
    let aux = '';
    if ((str !== undefined) && (str !== '')) {
      // eslint-disable-next-line no-plusplus
      for (let index = 11; index < 19; index++) {
        aux += str[index];
      }
    } else {
      aux = '00:00:00';
    }

    return aux;
  }

  function extraiData(str) {
    let aux = '';
    let d = '';
    let m = '';
    let y = '';
    if ((str !== undefined) && (str !== '')) {
      // 2022-03-18
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < 4; index++) {
        y += str[index];
      }
      // eslint-disable-next-line no-plusplus
      for (let index = 5; index < 7; index++) {
        m += str[index];
      }
      // eslint-disable-next-line no-plusplus
      for (let index = 8; index < 10; index++) {
        d += str[index];
      }
      // eslint-disable-next-line prefer-template
      aux = (d + '/' + m + '/' + y);
    } else {
      aux = '00/00/0000';
    }

    return aux;
  }

  React.useEffect(() => {
    setIsComplete(checkComplete());
  }, [data, pwData, changePw]);

  const checkComplete = () => {
    const internal = !!data.name;

    if (changePw) {
      return internal && pwData.ready;
    }

    return internal;
  };

  const breadcrumb = {
    title: 'Meu Perfil',
    routes: [
      { name: 'Perfil' }],
  };

  React.useEffect(() => {
    setLoading(true);
    setChangePw(localStorage.forceResetPassword === 'true');

    FockinkAPI.get('/customDashboards', {
      params: { status: 'A' },
    }).then((response) => {
      setDashList([
        ...dashList,
        ...(response.data.data || []).map((m) => ({ id: m.id, title: m.title })),
      ]);
      setLoading(false);
    });

    FockinkAPI.get('/login/myData').then((response) => {
      response.data.data.preferences ?
        (
          setData({
            ...response.data.data,
            tz: response.data.data.preferences.tz || 'America/Sao_Paulo',
            csv: response.data.data.preferences.csv || ';',
            csvString: response.data.data.preferences.csvString || '"',
            favoriteDashboard: response?.data?.data?.preferences.favoriteDashboard || 'general',
            expandedLineQuantity: response?.data?.data?.preferences.expandedLineQuantity || 5,
            modalLineQuantity: response?.data?.data?.preferences.modalLineQuantity || 5,
            pageLineQuantity: response?.data?.data?.preferences.pageLineQuantity || 10,
          })
        )
        :
        (
          setData({
            ...response.data.data,
            favoriteDashboard: response?.data?.data?.preferences?.favoriteDashboard || 'general',
            expandedLineQuantity: response?.data?.data?.preferences?.expandedLineQuantity || 5,
            modalLineQuantity: response?.data?.data?.preferences?.modalLineQuantity || 5,
            pageLineQuantity: response?.data?.data?.preferences?.pageLineQuantity || 10,
            preferences: {
              tz: 'America/Sao_Paulo',
              csv: ';',
              csvString: '"',
            },
          })
        );
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    ExternalApi.get('https://worldtimeapi.org/api/timezone')
      .then((response) => {
        if (typeof response.data !== 'undefined') {
          setTimeZones(response.data);
        }
      })
      .catch(() => {
        ExternalApi.get('https://www.timeapi.io/api/TimeZone/AvailableTimeZones')
          .then((response) => {
            if (typeof response.data !== 'undefined') {
              setTimeZones(response.data);
            }
          })
          .catch(() => {
            setTimeZones(['America/Sao_Paulo']);
          });
      });
  }, []);

  React.useEffect(() => {
    data.tz && data.tz !== undefined && data.tz !== '' && (
      ExternalApi.get(`https://worldtimeapi.org/api/timezone/${data.tz}`)
        .then((response) => {
          setUtc(response.data.utc_offset);
          setDateTime(response.data.datetime);
        })
        .catch(() => {
          ExternalApi.get(`https://www.timeapi.io/api/Time/current/zone?timeZone=${data.tz}`)
            .then((response) => {
              setUtc('');
              setDateTime(response.data.datetime);
            })
            .catch(() => {
              setUtc('');
              setDateTime('0000-00-00T00:00:00.000000-00:00');
            });
        })
    );
  }, [data.tz]);

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const save = () => {
    setIsSaving(true);

    const dtLines = {
      page: data?.pageLineQuantity,
      modal: data?.modalLineQuantity,
      expandedRows: data?.expandedLineQuantity,
    };

    localStorage.setItem('dtLines', JSON.stringify(dtLines));
    const sendData = {
      name: data.name,
      tz: data.tz || data.preferences.tz,
      csv: data.csv || data.preferences.csv,
      csvString: data.csvString || data.preferences.csvString,
      favoriteDashboard: data.favoriteDashboard || 'general',
      expandedLineQuantity: data.expandedLineQuantity,
      modalLineQuantity: data.modalLineQuantity,
      pageLineQuantity: data.pageLineQuantity,
    };
    if (changePw) {
      sendData.password = pwData.currentPw;
      sendData.newPassword = pwData.pw;
      sendData.confNewPassword = pwData.checkPw;
    }

    localStorage.setItem('favoriteDashboard', data.favoriteDashboard);

    FockinkAPI.put('/login/myData', sendData).then(() => {
      setReturnToMain(true);
    }).catch((e) => {
      setIsSaving(false);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e.response.data.reason || e.response.data.message,
      });
    });
  };

  if (returnToMain) return <Redirect to="/" />;
  if (loading) return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <h3 style={{ marginTop: -5, color: '#727272' }}>Geral</h3>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="name"
                    onChange={handleChange}
                    value={data.name || ''}
                    label="Nome"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RiAccountCircleFill size={20} />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    size="small"
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12} style={{ marginTop: 5 }}>
                  <Chip
                    variant="outlined"
                    label={`Email: ${user.email || ''}`}
                    icon={<RiMailFill size={18} />}
                    color="primary"
                    style={{ width: '100%', justifyContent: 'left' }}
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12} style={{ marginTop: 5 }}>
                  <Chip
                    variant="outlined"
                    label={`Nível de Acesso: ${user.profile.description || ''}`}
                    icon={<SiOpslevel size={18} />}
                    color="primary"
                    style={{ width: '100%', justifyContent: 'left' }}
                  />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={value}
              onChange={handleChangeTabControl}
              aria-label="nav tabs example"
              style={{ justifyContent: 'center' }}
            >
              <LinkTab
                icon={tabTitle[0].icon}
                label={tabTitle[0].title}
                {...a11yProps(0)}
                onClick={(event) => { event.preventDefault(); setChangePw(false); }}
                href="/0"
              />
              <LinkTab
                icon={tabTitle[1].icon}
                label={tabTitle[1].title}
                {...a11yProps(1)}
                onClick={(event) => { event.preventDefault(); user.authType === 'local' && (setChangePw(true)); }}
                href="/1"
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Card similar>
                    <CardContent>
                      <h4 style={{ marginTop: -5, marginBottom: 20, color: '#727272' }}>
                        Fuso Horário
                      </h4>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="tzLabel">Time Zone</InputLabel>
                        <Select
                          labelId="tzLabel"
                          id="tz"
                          name="tz"
                          value={data.tz || data.preferences.tz}
                          onChange={handleChange}
                          label="Time Zone"
                          startAdornment={(
                            <InputAdornment position="start">
                              <RiTimeFill size={20} />
                            </InputAdornment>
                            )}
                          fullWidth
                          size="small"
                        >
                          {
                            timeZones.map((option) => (

                              option === data?.preferences?.tz ?
                                <MenuItem key={option} value={option} selected>{option}</MenuItem>
                                :
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))
                          }

                        </Select>
                      </FormControl>
                      <Grid container spacing={1} xs={12} style={{ marginTop: 20, textAlign: 'left' }}>
                        <Grid item>
                          <Chip
                            variant="outlined"
                            label={`UTC: ${utc && utc} `}
                            color="primary"
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            variant="outlined"
                            label={`DATA: ${dateTime && extraiData(dateTime)} `}
                            color="primary"
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            variant="outlined"
                            label={`HORA: ${dateTime && extraiHora(dateTime)} `}
                            color="primary"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Card similar>
                    <CardContent>
                      <h4 style={{ marginTop: -5, marginBottom: 20, color: '#727272' }}>
                        Arquivo CSV
                      </h4>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="csvLabel">Separador</InputLabel>
                        <Select
                          labelId="csvLabel"
                          id="csv"
                          name="csv"
                          in
                          value={data.csv || data.preferences.csv}
                          onChange={handleChange}
                          label="Separador"
                          startAdornment={(
                            <InputAdornment position="start">
                              <RiSeparator size={20} />
                            </InputAdornment>
                          )}
                          fullWidth
                          size="small"
                        >
                          {
                              csvSeparator.map((option) => (
                                option === data?.preferences?.csv ? (
                                  <MenuItem
                                    key={option.id}
                                    value={option.separator}
                                    selected
                                  >
                                    {option.description}
                                  </MenuItem>
                                )
                                  : (
                                    <MenuItem
                                      key={option.id}
                                      value={option.separator}
                                    >
                                      {option.description}
                                    </MenuItem>
                                  )
                              ))
                            }

                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth style={{ marginTop: 20 }}>
                        <InputLabel id="csvStringLabel">Qualificador de Texto</InputLabel>
                        <Select
                          labelId="csvStringLabel"
                          id="csvString"
                          name="csvString"
                          in
                          value={data.csvString || data.preferences.csvString}
                          onChange={handleChange}
                          label="Qualificador de Texto"
                          startAdornment={(
                            <InputAdornment position="start">
                              <RiSeparator size={20} />
                            </InputAdornment>
                          )}
                          fullWidth
                          size="small"
                        >
                          {
                              csvQualificator.map((option) => (
                                option === data?.preferences?.csvString ? (
                                  <MenuItem
                                    key={option.id}
                                    value={option.separator}
                                    selected
                                  >
                                    {option.description}
                                  </MenuItem>
                                )
                                  : (
                                    <MenuItem
                                      key={option.id}
                                      value={option.separator}
                                    >
                                      {option.description}
                                    </MenuItem>
                                  )
                              ))
                            }

                        </Select>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Card similar>
                    <CardContent>
                      <h4 style={{ marginTop: -5, marginBottom: 20, color: '#727272' }}>
                        Dashboard Inicial
                      </h4>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="favoriteDashboard">Dashboards disponíveis</InputLabel>
                        <Select
                          labelId="favoriteDashboard"
                          id="favoriteDashboard"
                          name="favoriteDashboard"
                          value={data.favoriteDashboard}
                          onChange={handleChange}
                          label="Dashboards disponíveis"
                          startAdornment={(
                            <InputAdornment position="start">
                              <MdDashboard size={20} />
                            </InputAdornment>
                            )}
                          fullWidth
                          size="small"
                        >
                          {
                            dashList.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.title}
                              </MenuItem>
                            ))
                          }

                        </Select>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Card similar>
                    <CardContent>
                      <h4 style={{ marginTop: -5, marginBottom: 20, color: '#727272' }}>
                        Quantidade de linhas exibidas nas tabelas
                      </h4>
                      <Grid container gap={1} xs={12}>

                        <Grid item xs={12} lg={3.9}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="pageLineQuantity">Páginas</InputLabel>
                            <Select
                              labelId="pageLineQuantity"
                              id="pageLineQuantity"
                              name="pageLineQuantity"
                              value={data.pageLineQuantity}
                              onChange={handleChange}
                              label="Páginas"
                              startAdornment={(
                                <InputAdornment position="start">
                                  <MdOutlineFormatLineSpacing size={20} />
                                </InputAdornment>
                                )}
                              fullWidth
                              size="small"
                            >
                              {[5, 10, 15, 20, 50].map((num) => (
                                <MenuItem
                                  key={num}
                                  value={num}
                                >
                                  {`${num} linhas`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={3.9}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="modalLineQuantity">
                              Janelas
                            </InputLabel>
                            <Select
                              labelId="modalLineQuantity"
                              id="modalLineQuantity"
                              name="modalLineQuantity"
                              value={data.modalLineQuantity}
                              onChange={handleChange}
                              label="Janelas"
                              startAdornment={(
                                <InputAdornment position="start">
                                  <MdOutlineFormatLineSpacing size={20} />
                                </InputAdornment>
                                )}
                              fullWidth
                              size="small"
                            >
                              {[5, 10].map((num) => (
                                <MenuItem
                                  key={num}
                                  value={num}
                                >
                                  {`${num} linhas`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={3.9}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="expandedLineQuantity">
                              Submenus
                            </InputLabel>
                            <Select
                              labelId="expandedLineQuantity"
                              id="expandedLineQuantity"
                              name="expandedLineQuantity"
                              value={data.expandedLineQuantity}
                              onChange={handleChange}
                              label="Submenus"
                              startAdornment={(
                                <InputAdornment position="start">
                                  <MdOutlineFormatLineSpacing size={20} />
                                </InputAdornment>
                                )}
                              fullWidth
                              size="small"
                            >
                              {[5, 10, 15, 20, 50].map((num) => (
                                <MenuItem
                                  key={num}
                                  value={num}
                                >
                                  {`${num} linhas`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel
              value={value}
              index={1}
            >
              {
                }

              <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{ justifyContent: 'left' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ alignContent: 'left' }}>
                  <Card>
                    <CardContent>
                      {changePw ?
                        (
                          <>
                            {localStorage.forceResetPassword === 'true' && (
                            <Grid item xs={12}>
                              <h3 style={{ fontWeight: 500, marginTop: 0 }}>
                                Você precisa mudar sua senha para continuar com o acesso
                              </h3>
                            </Grid>
                            )}
                            <Grid item xs={12}>
                              <NewPasswordChecker data={pwData} setData={setPwData} currentPw />
                            </Grid>
                          </>
                        )
                        :
                        ''
                        }
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} style={classes.bottomButtons}>
        <BackButton back={() => setReturnToMain(true)} style={classes.btnSpace2} />

        <SaveButton
          save={save}
          isSaving={isSaving}
          style={classes.btnSpace}
          notComplete={!isComplete}
        // canSave={AdminPermissions.USERS_MANAGE.value}
        />
      </Grid>
    </>
  );
};
