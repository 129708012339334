import { React, createContext, useContext, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import FockinkAPI from '../middlewares/Api';

const selectPlantModalContext = createContext({});

const SelectPlantModalProvider = ({ children }) => {
  const [plants, setPlants] = useState([]);
  const [open, setOpen] = useState(false);

  const addPlant = useCallback(
    (newPlant) => {
      setPlants((oldState) => [...oldState, newPlant]);
    },
    [plants],
  );

  const directSet = useCallback(
    (newList) => {
      setPlants(newList);
    },
    [plants],
  );

  const removePlant = useCallback(
    (plantId) => {
      setPlants((oldState) =>
        oldState.filter((plant) => plant.id !== plantId));
    },
    [plants],
  );

  const handleModal = (newVal) => {
    if (newVal && typeof newVal === 'boolean') {
      setOpen(newVal);
    }

    setOpen((oldState) => !oldState);
  };

  const fetchPlants = useCallback(async () => {
    try {
      const accounts = FockinkAPI.get('/accounts');

      const equips = FockinkAPI.get('/equipaments');
      const plantsData = FockinkAPI.get('/plants', {
        params: { extended: true },
      });

      const results = await Promise.allSettled([accounts, equips, plantsData]);
      const status = results.every((result) => result.status === 'fulfilled');

      if (!status) {
        toast.error('Falha na busca pelos dados');
      }

      const onlyUnits = results[1].value.data.data
        .map((equipament) => equipament?.unit)
        .filter((each) => each !== undefined && each.id);
      const uniqueUnits = new Map(onlyUnits.map((pos) => [pos.id, pos]));

      const returnData = {
        account: results[0].value.data.data,
        equipaments: results[1].value.data.data,
        plants: results[2].value.data.data,
        units: [...uniqueUnits.values()],
      };

      return { success: true, data: returnData };
    } catch (error) {
      toast.error(error.message);
      return { success: false, data: {} };
    }
  }, [plants]);

  return (
    <selectPlantModalContext.Provider
      value={{
        plants,
        open,
        addPlant,
        removePlant,
        handleModal,
        fetchPlants,
        directSet,
      }}
    >
      {children}
    </selectPlantModalContext.Provider>
  );
};

const useSelectPlantModal = () => {
  const context = useContext(selectPlantModalContext);

  if (!context) {
    throw new Error(
      'useSelectPlantModal must be within an SelectPlantModalProvider',
    );
  }

  return context;
};

export { useSelectPlantModal, SelectPlantModalProvider };
