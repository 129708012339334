import FockinkAPI from '../../../middlewares/Api';

export async function createFeedBack(body/* { message, sourcePage } */) {
  let r = false;
  // if ((!message) || (!sourcePage)) {
  //   return r;
  // }

  // const sendData = { message, sourcePage };
  await FockinkAPI.post('contactUs/', body).then((result) => {
    r = result.data.success;
  }).catch(() => { r = false; });

  return r;
}

export async function ListFeedBack({ accountId }) {
  let r = false;
  if ((!accountId)) {
    await FockinkAPI.get('contactUs/').then((result) => {
      r = result.data.data;
    }).catch(() => { r = false; });
  } else {
    await FockinkAPI.get(`contactUs/?accountId=${accountId}`).then((result) => {
      r = result.data.data;
    }).catch(() => { r = false; });
  }

  return r;
}

export async function DeleteFeedBack({ feedbackId }) {
  let r = false;
  await FockinkAPI.delete(`contactUs/${feedbackId}`)
    .then((result) => { r = result.data.success; })
    .catch(() => { r = false; });
  return r;
}

export async function ReadFeedBack({ feedbackId }) {
  let r = false;

  await FockinkAPI.get(`contactUs/${feedbackId}`).then((result) => {
    r = result.data.data;
  }).catch(() => { r = false; });

  return r;
}

export async function EditFeedBack({ feedbackId, sendData }) {
  let r = false;
  await FockinkAPI.put(`contactUs/${feedbackId}`, sendData).then((result) => {
    r = result.data.success;
  }).catch(() => { r = false; });
  return r;
}

export async function ReadFile({ feedbackId, file }) {
  let r = false;

  await FockinkAPI.get(`contactUs/${feedbackId}/readFile?fileName=${file}`).then((result) => {
    r = result.data.data;
  }).catch(() => { r = false; });

  return r;
}

export async function DeleteFile({ feedbackId, file }) {
  let r = false;
  await FockinkAPI.delete(`contactUs/${feedbackId}/deleteFile?fileName=${file}`)
    .then((result) => { r = result.data.success; })
    .catch(() => { r = false; });
  return r;
}

export async function AnswerFeedBack({ feedbackId, sendData }) {
  let r = false;
  await FockinkAPI.put(`contactUs/${feedbackId}/answer`, sendData).then((result) => {
    r = result.data.success;
  }).catch(() => { r = false; });
  return r;
}
