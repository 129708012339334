export function normalizeErrorData(data, concat) {
  const normalizeData = Number(data);
  if (normalizeData === 999) {
    return 'OFF';
  }
  if (normalizeData === 998) {
    return 'N/D';
  }
  if (normalizeData === 997) {
    return 'ERR';
  }
  if (normalizeData === -9999999) {
    return ' ';
  }
  return `${data}${concat || ''}`;
}
