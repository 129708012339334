import React from 'react';
import { toast } from 'react-toastify';
import { handleMessageError } from '../helpers/handlerMessageError';
import FockinkAPI from '../middlewares/Api';

const TechnicalDeliveryContext = React.createContext(null);

function TechnicalDeliveryProviderHook({ children }) {
  const [
    selectedsTechnicalDeliveryToEdit,
    setSelectedsTechnicalDeliveryToEdit,
  ] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [technicalDelivery, setTechnicalDelivery] = React.useState(
    () => JSON.parse(localStorage.getItem('@FOKINK_TECHNICAL_DELIVERY')),
    // eslint-disable-next-line function-paren-newline
  );
  const [gnfData, setGnfData] = React.useState();

  const [levelStepp, setLevelStepp] = React.useState(() => {
    const lastStep = Number(
      localStorage.getItem('@FOKINK_TECHNICAL_DELIVERY_LAST_STEP'),
    );
    return lastStep || 0;
  });

  const [tecnichalDeliveryList, setTecnichalDeliveryList] = React.useState([]);

  const handleUploadSignatureWarranty = async (signature) => {
    try {
      setLoading(true);
      const formdata = new FormData();
      formdata.append('uploadedFile', signature);

      await FockinkAPI.post(
        `technicalDelivery/${technicalDelivery.id}/signByImage`,
        formdata,
      );
      toast.success('Entrega Técnica assinada com sucesso!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleMessageError(error);
    }
  };

  const saveDataActions = (data) => {
    if (levelStepp + 1 === 7) {
      localStorage.removeItem('@FOKINK_TECHNICAL_DELIVERY');
      setTechnicalDelivery();
    } else {
      setTechnicalDelivery(data);
      localStorage.setItem('@FOKINK_TECHNICAL_DELIVERY', JSON.stringify(data));
    }
  };

  const saveLastStepOnLocalStorage = () => {
    if (levelStepp + 1 === 7) {
      localStorage.removeItem('@FOKINK_TECHNICAL_DELIVERY_LAST_STEP');
      setLevelStepp(0);
    } else {
      localStorage.setItem(
        '@FOKINK_TECHNICAL_DELIVERY_LAST_STEP',
        levelStepp + 1,
      );
      setLevelStepp((preventState) => preventState + 1);
    }
  };

  async function handleGetTechnicalDeliveryList(parameters) {
    try {
      const newParam = { ...parameters };
      Object.keys(newParam).forEach((e) => {
        if (newParam[e] === '') {
          delete newParam[e];
        }
      });
      const response = await FockinkAPI.get('technicalDelivery/', {
        params: newParam,
      });
      const { data } = response.data;
      setTecnichalDeliveryList(data);
    } catch (error) {
      toast.error('Não foi possivel buscar os dados de entrega técnica');
    }
  }

  async function createGnfTicketValidateValidate(gnfValue) {
    try {
      const response = await FockinkAPI.post(
        'technicalDelivery/validateTicket',
        gnfValue,
      );

      const { data } = response.data;
      setGnfData(data);
      toast.success('GNF validado com sucesso');
      return data;
    } catch (error) {
      handleMessageError(error);
    }
    return null;
  }

  async function createTechnicalDelivrey(technicalDeliveryData) {
    try {
      const response = await FockinkAPI.post(
        'technicalDelivery/centralPivot',
        technicalDeliveryData,
      );

      if (technicalDelivery) {
        toast.info('Entrega técnica já inicializada');
        return technicalDelivery;
      }

      const { data } = response.data;
      saveDataActions(data);
      setGnfData();
      saveLastStepOnLocalStorage();
      toast.success('Entrega tecnica criada com sucesso');
      return data;
    } catch (error) {
      toast.error(JSON.parse(error.request.response).message);
    }

    return technicalDelivery;
  }

  async function handleUpdateTechnicalDelivery(newData, succesMessage) {
    try {
      const response = await FockinkAPI.put(
        `technicalDelivery/centralPivot/${technicalDelivery.id}/step${
          levelStepp + 1
        }`,
        newData,
      );

      const { data } = response.data;

      saveDataActions(data);
      saveLastStepOnLocalStorage();
      toast.success(succesMessage);
    } catch (error) {
      handleMessageError(error);
    }
  }

  const handleSelectTechnicalDelivery = (technicalData) => {
    setSelectedsTechnicalDeliveryToEdit(true);
    setTechnicalDelivery(technicalData);
    setLevelStepp(0);
    localStorage.setItem(
      '@FOKINK_TECHNICAL_DELIVERY',
      JSON.stringify(technicalData),
    );
  };

  const handleClearForm = () => {
    setTechnicalDelivery();
    localStorage.removeItem('@FOKINK_TECHNICAL_DELIVERY');
  };

  return (
    <TechnicalDeliveryContext.Provider
      value={{
        technicalDelivery,
        tecnichalDeliveryList,
        createTechnicalDelivrey,
        gnfData,
        createGnfTicketValidateValidate,
        handleUpdateTechnicalDelivery,
        handleGetTechnicalDeliveryList,
        handleSelectTechnicalDelivery,
        handleClearForm,
        levelStepp,
        setLevelStepp,
        setSelectedsTechnicalDeliveryToEdit,
        selectedsTechnicalDeliveryToEdit,
        loading,
        handleUploadSignatureWarranty,
      }}
    >
      {children}
    </TechnicalDeliveryContext.Provider>
  );
}

function useTechnicalDelivery() {
  const context = React.useContext(TechnicalDeliveryContext);

  if (!context) {
    throw new Error('Outside context');
  }

  return context;
}

export { TechnicalDeliveryProviderHook, useTechnicalDelivery };
