import React from 'react';
import Button from '@material-ui/core/Button';
import { MdDone, MdBrokenImage, MdNotInterested } from 'react-icons/md';
import CircularProgress from '@material-ui/core/CircularProgress';
import { hasPermission } from '../../helpers/permission';

export default (props) => {
  const disabled = props.isSaving || props.notComplete || props.isDisabled;

  const text = () => {
    if (props.isSaving) return 'Salvando';
    if (props.notComplete) return 'Verifique os campos';
    return props.customSaveText || 'Salvar';
  };

  const icon = () => {
    if (props.isSaving) return <CircularProgress size={26} />;
    if (props.notComplete) return <MdBrokenImage />;
    return <MdDone />;
  };

  const defaultButton = (
    <Button
      disabled={disabled}
      size="large"
      variant="contained"
      color="secondary"
      className={props.className}
      style={{
        background: disabled ? '#CECECE' : '#28AE60',
        color: 'white',
        ...props.style,
      }}
      onClick={() => props.save()}
      startIcon={icon()}
    >
      {text()}
    </Button>
  );

  const notAllowButton = (
    <Button
      disabled
      size="large"
      variant="contained"
      color="secondary"
      className={props.className}
      style={{ background: '#CECECE', color: 'white', ...props.style }}
      startIcon={<MdNotInterested />}
    >
      {/* Você não tem permissão para salvar */}
      Salvar
    </Button>
  );

  if (props.canSave) {
    if (hasPermission(props.canSave)) {
      return defaultButton;
    }
    return notAllowButton;
  }
  return defaultButton;
};
