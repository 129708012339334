import React, { useState, createContext } from 'react';

export const PageContext = createContext({});

function PageProvider({ children }) {
  const [backPageContext, setBackPageContext] = useState('teste1');
  const [plantIDContext, setPlantIDContext] = useState('');

  return (
    <PageContext.Provider
      value={{
        backPageContext,
        setBackPageContext,
        plantIDContext,
        setPlantIDContext,
      }}
    >
      { children }
    </PageContext.Provider>
  );
}

export default PageProvider;
