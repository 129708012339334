import React from 'react';
import { toast } from 'react-toastify';
import { handleMessageError } from '../../helpers/handlerMessageError';
import FockinkAPI from '../../middlewares/Api';

const TechnicalDeliveryThermomeryCentralContext = React.createContext(null);

export function TechnicalDeliveryThermomeryCentralProvider({ children }) {
  const [levelStep, setLevelStep] = React.useState(() => {
    const lastStep = Number(
      localStorage.getItem('@FOKINK_TECHNICAL_DELIVERY_LAST_STEP'),
    );
    return lastStep || 0;
  });

  const saveDataActions = (data) => {
    if (levelStep + 1 === 11) {
      localStorage.removeItem('@FOKINK_TECHNICAL_DELIVERY_THERMOMETRY_CENTRAL');
      localStorage.removeItem('@FOKINK_TECHNICAL_DELIVERY_LAST_STEP');
      setData();
    } else {
      setData(data);
      setLevelStep((preventState) => preventState + 1);
      localStorage.setItem(
        '@FOKINK_TECHNICAL_DELIVERY_LAST_STEP',
        levelStep + 1,
      );
      localStorage.setItem(
        '@FOKINK_TECHNICAL_DELIVERY_THERMOMETRY_CENTRAL',
        JSON.stringify(data),
      );
    }
  };

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(() => {
    const currentData = JSON.parse(
      localStorage.getItem('@FOKINK_TECHNICAL_DELIVERY_THERMOMETRY_CENTRAL'),
    );
    if (!currentData) {
      return {};
    }
    return currentData;
  });

  const handleChnageLevelStep = (newStepValue) => setLevelStep(newStepValue);

  const handlePersistTechnicalDeliveryDataState = (currentState) =>
    localStorage.setItem(
      '@FOKINK_TECHNICAL_DELIVERY_THERMOMETRY_CENTRAL',
      JSON.stringify(currentState),
    );

  const handleExcludeTechnicalDeliveryFromStorage = () => {
    localStorage.removeItem('@FOKINK_TECHNICAL_DELIVERY_THERMOMETRY_CENTRAL');
  };

  const handleConsultGNFCode = async (gnfTicketData) => {
    try {
      setLoading(true);
      const response = await FockinkAPI.post(
        '/technicalDelivery/validateTicket',
        gnfTicketData,
      );

      const { codigo, clienteNegocio, clienteObra } = response.data.data;
      const { cep, logradouro, municipio, estado } = clienteObra;
      const formatNewData = {
        gnf: codigo.toString(),
        client: clienteNegocio.nome,
        farm: clienteObra.nome,
        address: {
          cep,
          street: logradouro,
          city: municipio,
          state: estado,
        },
      };
      handlePersistTechnicalDeliveryDataState(formatNewData);
      setData(formatNewData);
      toast.success('GNF validado com sucesso');
    } catch (error) {
      toast.error('Registro não encontrado');
    }
  };

  const handleCreateTechnicalDelivery = async (technicalDeliveryData) => {
    try {
      setLoading(true);
      const response = await FockinkAPI.post(
        '/technicalDelivery/centralThermometry',
        technicalDeliveryData,
      );
      handlePersistTechnicalDeliveryDataState(response.data.data);
      toast.success(
        'Entrega Técnica de Termometria Central criado com sucesso!',
      );
      setLoading(false);
      saveDataActions(response.data.data);
    } catch (error) {
      setLoading(false);
      handleMessageError(error);
    }
  };

  const updateTechnicalDeliveryCentral = async (tehnicalDeliveryData) => {
    try {
      setLoading(true);
      const response = await FockinkAPI.put(
        `technicalDelivery/centralThermometry/${data.id}/step${levelStep + 1}`,
        tehnicalDeliveryData,
      );
      toast.success(`Step ${levelStep + 1} cadastrado com sucesso`);
      setLoading(false);
      saveDataActions(response.data.data);
    } catch (error) {
      handleMessageError(error);
    }
  };

  const handleSelectTechnicalDeliveryThermometry = (technicalData) => {
    handlePersistTechnicalDeliveryDataState(technicalData);

    setData(technicalData);
  };

  return (
    <TechnicalDeliveryThermomeryCentralContext.Provider
      value={{
        data,
        levelStep,
        setLevelStep,
        handleChnageLevelStep,
        loading,
        handleConsultGNFCode,
        handleCreateTechnicalDelivery,
        handleExcludeTechnicalDeliveryFromStorage,
        handleSelectTechnicalDeliveryThermometry,
        updateTechnicalDeliveryCentral,
      }}
    >
      {children}
    </TechnicalDeliveryThermomeryCentralContext.Provider>
  );
}

export function useTechnicalDeliveryThermomeryCentral() {
  const context = React.useContext(TechnicalDeliveryThermomeryCentralContext);
  if (!context) {
    throw new Error(
      'TechnicalDeliveryThermomeryCentralContext must be used inside context',
    );
  }

  return context;
}
