import Enum from 'enum';

const AdminPermissions = new Enum(
  {
    ADMINSTRATORS_VIEW: 10,
    ADMINSTRATORS_MANAGE: 11,
    //
    ORGANIZATIONS_VIEW: 20,
    ORGANIZATIONS_MANAGE: 21,
    //
    ACCOUNTS_VIEW: 30,
    ACCOUNTS_MANAGE: 31,
    //
    USERS_VIEW: 40,
    USERS_MANAGE: 41,
    //
    PLANTS_VIEW: 50,
    PLANTS_MANAGE: 51,
    //
    EQUIPAMENTS_VIEW: 60,
    EQUIPAMENTS_MANAGE: 61,
    //
    COMMUNICATORS_VIEW: 70,
    COMMUNICATORS_MANAGE: 71,
  },
  { freeze: true },
);

export default AdminPermissions;
