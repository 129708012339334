import React from 'react';
import '../../App.css';
import { FormControl, OutlinedInput, InputAdornment, InputLabel, IconButton } from '@mui/material';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

export default (props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <FormControl variant="outlined" style={props.style}>
      <InputLabel htmlFor="password" style={{ backgroundColor: '#FFFFFF', padding: 5, marginTop: -5 }}>{props.label}</InputLabel>
      <OutlinedInput
        name={props.name}
        id={props.name}
        type={showPassword ? 'text' : 'password'}
        value={props.value}
        onChange={props.handleChange}
        variant="outlined"
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
            </IconButton>
          </InputAdornment>
        )}
        {...props}
      />
    </FormControl>
  );
};
