import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './account';
import { adminTheme, clientTheme } from '../styles/themes';

const ThemeContext = createContext(null);

const ThemeProviderHook = ({ children }) => {
  const { user } = useAuth();

  const [selectedTheme, setSelectedTheme] = useState(clientTheme);

  useEffect(() => {
    (() => {
      if (user && user.profileType === 'admin') {
        setSelectedTheme(adminTheme);
      } else {
        setSelectedTheme(clientTheme);
      }
    })();
  }, [user]);

  return (
    <ThemeContext.Provider value={{ userTheme: selectedTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useThemeHook = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeHook must be within an ThemeProviderHook');
  }

  return context;
};

export { ThemeProviderHook, useThemeHook };
