import React from 'react';
import { toast } from 'react-toastify';
import FockinkAPI from '../middlewares/Api';

const TelemetryVariableContext = React.createContext(null);

export function TelemetryVariableProvider({ children }) {
  const DEFAULT_FILTER_VALUES = {
    system: '',
    variablePattern: '',
    friendlyName: '',
    alarmAvailable: 'false',
    status: 'A',
  };

  const [telemetryVariables, setVariables] = React.useState([]);
  const [
    telemetryVariablesFiltes,
    setTelemetryVariablesFiltes,
  ] = React.useState(DEFAULT_FILTER_VALUES);

  const handleGetTelemetryVariables = async () => {
    try {
      const response = await FockinkAPI.get('/telemetryVariablesAccount/');
      const { itemsDefault, itemsCustom } = response.data.data;

      const formattedCustomItemns = itemsCustom.map(
        ({ telemetryVariablesId, telemetryVariablesInfo }) => ({
          id: telemetryVariablesId,
          system: telemetryVariablesInfo.system,
          friendlyName: telemetryVariablesInfo.friendlyName,
          alarmAvailable: telemetryVariablesInfo.alarmAvailable,
        }),
      );

      const formattedDefaultItemns = itemsDefault.map(
        ({ id, friendlyName, system, alarmAvailable }) => ({
          id,
          system,
          friendlyName,
          alarmAvailable,
        }),
      );

      setVariables([...formattedDefaultItemns, ...formattedCustomItemns]);
    } catch (error) {
      toast.error(
        'Não foi possível buscar informações de variáveis do sistema',
      );
    }
  };

  return (
    <TelemetryVariableContext.Provider
      value={{
        telemetryVariables,
        handleGetTelemetryVariables,
        telemetryVariablesFiltes,
        setTelemetryVariablesFiltes,
        DEFAULT_FILTER_VALUES,
      }}
    >
      {children}
    </TelemetryVariableContext.Provider>
  );
}

export function useTelemetryVariablesHook() {
  const context = React.useContext(TelemetryVariableContext);
  if (!context) {
    throw new Error('useTelemetry hook must be used inside context');
  }

  return context;
}
