import React from 'react';
import FockinkAPI from '../middlewares/Api';

const EquipamentContext = React.createContext(null);

export function EquipamentProvider({ children }) {
  const getEquipamentList = async () => {
    try {
      const response = await FockinkAPI.get('/dashboards/equipaments');
      const { data } = response.data;
      return data.equipaments;
    } catch (error) {
      console.error('getEquipamentList', error);
    }
  };

  return (
    <EquipamentContext.Provider value={{ equipaments: [], getEquipamentList }}>
      {children}
    </EquipamentContext.Provider>
  );
}

export function useEquipamentContext() {
  const context = React.useContext(EquipamentContext);
  if (!context) {
    throw new Error('Outside context');
  }

  return context;
}
