// Basic
import axios from 'axios';

// Init axios object
const ExternalApi = axios.create({
  // baseURL: process.env.REACT_APP_BACKEND_API,
  timeout: 30000,
});

ExternalApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export default ExternalApi;
