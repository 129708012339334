import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Login from '../pages/auth/Login/Index';
import Forbidden403 from '../pages/auth/Forbidden403';
import Profile from '../pages/auth/Profile/Profile';
import { hasPermission } from '../helpers/permission';

const PrivateRoute = ({ ...rest }) => {
  const token = localStorage.getItem('@FockinkAPP:token');
  const iotPortalToken = localStorage.getItem('fockink:portaliot');
  const lastPage = localStorage.getItem('sessionLastPage');

  if (!token || !iotPortalToken) {
    return (
      <Redirect
        push
        to={{
          pathname: '/login',
          state: { from: lastPage },
        }}
      />
    );
  }
  let isAllow;

  if (token) {
    const { profile: { profileId } } = jwtDecode(token);
    if (profileId === 14 && rest.permissionId !== undefined) {
      isAllow = false;
    } else {
      isAllow = hasPermission(rest.permissionId);
    }
  }

  if (localStorage[process.env.REACT_APP_STORAGE_KEY] && isAllow && localStorage.forceResetPassword !== 'true') {
    // TODO: check if the token is valid
    return (<Route {...rest} />);
  }

  // if (localStorage[process.env.REACT_APP_STORAGE_KEY] && localStorage.forceResetPassword === 'true') {
  //   const rst = rest;
  //   delete rst.component;
  //   return (
  //     <Route
  //       {...rst}
  //       render={({ location }) => {
  //         if (location.pathname === '/profile') {
  //           return <Profile />;
  //         }
  //         return (
  //           <Redirect
  //             push
  //             to={{
  //               pathname: '/profile',
  //               state: { from: location },
  //             }}
  //           />
  //         );
  //       }}
  //     />
  //   );
  // }

  if (localStorage[process.env.REACT_APP_STORAGE_KEY] && !isAllow) {
    const rst = rest;
    delete rst.component;
    return (
      <Route
        {...rst}
        render={({ location }) => {
          if (location.pathname === '/403') {
            return <Forbidden403 />;
          }
          return (
            <Redirect
              push
              to={{
                pathname: '/403',
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (location.pathname === '/login') {
          return <Login />;
        }
        return (
          <Redirect
            push
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default { PrivateRoute };
