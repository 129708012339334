import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { isSupported } from '../helpers/browser';

export default () => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const suported = isSupported();
    if (!suported.is) {
      setOpen(suported.current);
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      // disableBackdropClick
    >
      <DialogTitle id="alert-dialog-title">
        Seu navegador não é totalmente suportado
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Por favor, utilize preferencialmente a ULTIMA VERSÃO do Google Chrome
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {`Atual: ${open}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
