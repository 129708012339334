/* eslint-disable indent */
import React from 'react';
import {
  AppBar,
  // CssBaseline,
  Divider,
  Hidden,
  IconButton,
  List,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Typography,
  Tooltip,
  Toolbar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  Badge,
} from '@mui/material';
import moment from 'moment';

// MATERIAL V4 LEGACY - Trace Strategi for Migrate a new version /////////////////
import { useTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// //////////////////////////////////////////////////////////////////////////////

// REACT ICONS ////////////////////////////////////////
import {
  MdAccountCircle,
  MdChevronLeft,
  MdChevronRight,
  MdLogout,
  MdMenu,
  MdWarning,
} from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2';
// ////////////////////////////////////////////////////

import { useLocation, Link, Redirect, useHistory } from 'react-router-dom';

import { isMobileDevice } from '../helpers/checkMobileDevice';
import BuildRoutes from './BuildRoutes';
import BrowserAlert from '../components/BrowserAlert';
// import Footer from '../components/Footer';
import { isSupported } from '../helpers/browser';
import '../App.css';
import { useThemeHook } from '../hooks/useTheme';
import ModalFeedBack from '../pages/ContactUs/ModalFeedBack';
import { FullScreenButton } from '../components/FullScreenButton';
import FockinkAPI from '../middlewares/Api';

const drawerWidth = 270;

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [navLinks, setNavLinks] = React.useState(false);
  const [releaseVersion, setReleaseVersion] = React.useState({});
  const location = useLocation();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [openModalFeedBack, setOpenModalFeedBack] = React.useState(false);
  const { userTheme } = useThemeHook();

  const classes = {
    // root: {
    //     display: 'flex',
    //     height: '100%',
    // },
    appBarShift: {
      width: '100%',
    },
    userName: {
      fontSize: '15px',
      fontWeight: '700',
      margin: '0 10px',
      cursor: 'pointer',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    title: {
      flexGrow: 1,
      fontSize: '15px',
      paddingLeft: '20px',
      display: 'flex',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 10,
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      position: 'relative',
    },
    contentShift: {},
  };

  function buildRoutes(token, profile) {
    setNavLinks(BuildRoutes.navbarLinks(token, profile));

    const route = BuildRoutes.findByLink(location.pathname);
    setShowMenu(route ? route.noMenu || false : false);
    setOpen(false);
    setRedirect(false);
    if (
      ['/login', '/logout', '/resetPassword'].indexOf(location.pathname) === -1
    ) {
      window.localStorage.setItem('sessionLastPage', location.pathname);
    }
  }
  const verifyLoggedIn = localStorage.getItem(
    `${process.env.REACT_APP_STORAGE_KEY}`,
  );

  React.useEffect(() => {
    const token = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}`);
    buildRoutes(token);

    if (token) {
      FockinkAPI.get('/releaseNotes/listIds', {
        params: {
          isAdminPortal: 0,
        },
      }).then((res) => {
        if (res.data.data && res.data.data[0]) {
          setReleaseVersion(res.data.data[0]);
        }
      });
    }
  }, [
    localStorage.getItem('profile'),
    localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}`),
  ]);

  React.useEffect(() => {
    function handleStorage() {
      const token = localStorage.getItem(
        `${process.env.REACT_APP_STORAGE_KEY}`,
      );
      buildRoutes(token);
    }

    window.addEventListener('storage', handleStorage());

    return () => window.removeEventListener('storage', handleStorage());
  }, [location]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleOpenModalFeedBack() {
    setOpenModalFeedBack(true);
  }

  const history = useHistory();
  return (
    <ThemeProvider theme={userTheme}>
      <div style={classes}>
        <BrowserAlert />
        <CssBaseline />
        <AppBar
          style={showMenu ? { display: 'none' } : {}}
          position="fixed"
          sx={{ backgroundColor: '#000000' }}
        >
          <Toolbar>
            {localStorage.getItem('profile') && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(true)}
                edge="start"
                style={{
                  marginTop: 5,
                  marginRight: theme.spacing(2),
                  display: open ? 'none' : 'initial',
                }}
              >
                <MdMenu />
              </IconButton>
            )}
            <Hidden only="xs">
              <img
                src="/images/IoT Fockink/Ativo 6@3x.png"
                alt="logo"
                style={{ height: '55px' }}
              />
            </Hidden>
            <Hidden smUp>
              <img
                src="/images/IoT Fockink/Ativo 3@3x.png"
                alt="logo"
                style={{ height: '35px' }}
              />
            </Hidden>
            {isSupported().is ? (
              <Typography variant="h6" noWrap style={classes.title}>
                {' '}
              </Typography>
            ) : (
              <Tooltip title="Seu navegador não é totalmente suportado - Por favor, utilize preferencialmente a ULTIMA VERSÃO do Google Chrome">
                <Typography variant="h6" noWrap style={classes.title}>
                  <MdWarning style={{ marginRight: '5px' }} />
                  Navegador
                </Typography>
              </Tooltip>
            )}

            {localStorage[process.env.REACT_APP_STORAGE_KEY] ? (
              <>
                <Hidden only="xs">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <span style={classes.userName} onClick={handleClick}>
                    {localStorage.name}
                  </span>
                </Hidden>
                <IconButton
                  color="inherit"
                  onClick={handleClick /* setRedirect('/profile') */}
                >
                  <MdAccountCircle />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      window.location.href = '/profile';
                    }}
                    style={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignContent: 'center',
                    }}
                  >
                    <div>
                      <CgProfile size={20} color="#727272" />
                    </div>
                    <div
                      style={{
                        marginLeft: 12,
                        marginTop: -5,
                        color: '#0000008A',
                      }}
                    >
                      Meu Perfil
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpenModalFeedBack();
                    }}
                    style={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignContent: 'center',
                    }}
                  >
                    <div>
                      <HiOutlineChatAlt2 size={20} color="#727272" />
                    </div>
                    <div
                      style={{
                        marginLeft: 12,
                        marginTop: -5,
                        color: '#0000008A',
                      }}
                    >
                      Fale Conosco
                    </div>
                  </MenuItem>
                  {!isMobileDevice() && <FullScreenButton color="#727272" />}
                  <MenuItem
                    onClick={() => {
                      window.location.href = '/releases';
                    }}
                  >
                    <Badge
                      color="error"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      badgeContent={1}
                      variant="dot"
                      invisible={
                        !(
                          releaseVersion &&
                          moment(
                            releaseVersion.releaseDate,
                            'DD-MM-YYYY',
                          ).isAfter(moment().subtract(5, 'days'))
                        )
                      }
                      sx={{ zIndex: 4 }}
                    >
                      <div>
                        <HiOutlineClipboardDocumentList
                          size={20}
                          color="#727272"
                        />
                      </div>
                    </Badge>
                    <div
                      style={{
                        marginLeft: 12,
                        marginTop: -5,
                        color: '#0000008A',
                      }}
                    >
                      Release Notes
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      window.location.href = '/logout';
                    }}
                  >
                    <div>
                      <MdLogout size={20} color="#727272" />
                    </div>
                    <div
                      style={{
                        marginLeft: 12,
                        marginTop: -5,
                        color: '#0000008A',
                      }}
                    >
                      Sair
                    </div>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <></>
            )}
          </Toolbar>
          <ModalFeedBack
            openModal={openModalFeedBack}
            closeModal={setOpenModalFeedBack}
          />
        </AppBar>
        <SwipeableDrawer
          style={classes.drawer}
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          classes={{
            paper: classes.drawerPaper,
          }}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
        >
          <div style={classes.drawerHeader}>
            <IconButton onClick={() => setOpen(false)}>
              {theme.direction === 'ltr' ? (
                <MdChevronLeft />
              ) : (
                <MdChevronRight />
              )}
            </IconButton>
          </div>
          <Divider />
          <List sx={{ width: 240 }}>
            {navLinks &&
              localStorage.getItem('profile') &&
              navLinks.map((e) => (
                <Link
                  to={e.path}
                  key={e.path}
                  style={{ textDecoration: 'none' }}
                >
                  <ListItemButton>
                    <ListItemIcon>{e.icon}</ListItemIcon>
                    <ListItemText
                      sx={{ color: '#0000008A' }}
                      primary={e.name}
                    />
                  </ListItemButton>
                </Link>
              ))}
          </List>
          <Grid
            container
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              bottom: 0,
              left: 0,
            }}
          >
            {releaseVersion && (
              <Typography
                sx={{
                  color: '#0000008A',
                  padding: 1,
                  '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
                }}
                variant="caption2"
                onClick={() => history.push('/releases')}
              >
                {`Portal IOT versão ${(releaseVersion || {}).version}`}
              </Typography>
            )}
          </Grid>
        </SwipeableDrawer>
        {location.pathname !== '/' && (
          <main className={`${showMenu ? 'content' : 'main-container'}`}>
            <div style={showMenu ? { display: 'none' } : {}}>
              <div style={classes.drawerHeader} />
            </div>
            {/* <Container style="container"> */}
            <>{BuildRoutes.routerLinks()}</>
          </main>
        )}
        {location.pathname === '/' && <>{BuildRoutes.routerLinks()}</>}
      </div>
    </ThemeProvider>
  );
}
