/* eslint-disable function-paren-newline */
import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {
  MdAssessment,
  MdAssignmentInd,
  MdBusiness,
  MdDashboard,
  MdLocalAtm,
  MdPerson,
  MdQuestionAnswer,
  MdSettings,
  MdTrendingUp,
  MdVerticalSplit,
} from 'react-icons/md';

import PR from './PrivateRoute';
import Loading from '../components/Loading';
import AdminPermissions from '../helpers/enums/AdminPermissions';

const WeatherStationSingleDash = lazy(() => import(/* webpackChunkName: "WeatherStationSingleDash" */ '../pages/adminClient/Dashes/WeatherStationSingle/WeatherStationSingleDash'));
const WeatherStationSingleSettings = lazy(() => import(/* webpackChunkName: "WeatherStationSingleSettings" */ '../pages/adminClient/Dashes/WeatherStationSingle/WeatherStationSingleSettings'));
const Home = lazy(() => import(/* webpackChunkName: "Home" */ '../pages/Home'));
const Settings = lazy(() => import(/* webpackChunkName: "Settings" */ '../pages/settings/Settings'));
const BlockedHosts = lazy(() => import(/* webpackChunkName: "BlockedHosts" */ '../pages/settings/admin/BlockedHosts'));
const TechDeliveryTypes = lazy(() => import(/* webpackChunkName: "TechDeliveryTypes" */ '../pages/settings/admin/TechDeliveryTypes'));
const Login = lazy(() => import(/* webpackChunkName: "Login" */ '../pages/auth/Login/Index'));
const Logout = lazy(() => import(/* webpackChunkName: "Logout" */ '../pages/auth/Login/Logout'));
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ '../pages/auth/Profile/Profile'));
const ResetPassword = lazy(() => import(/* webpackChunkName: "ResetPassword" */ '../pages/auth/Login/ResetPassword'));
const Organization = lazy(() => import(/* webpackChunkName: "Organization" */ '../pages/adminFockink/organization/Organization'));
const OrganizationForm = lazy(() => import(/* webpackChunkName: "OrganizationForm" */ '../pages/adminFockink/organization/OrganizationForm'));
const OrganizationCreate = lazy(() => import(/* webpackChunkName: "OrganizationCreate" */ '../pages/adminFockink/organization/OrganizationCreate'));
const TechnicalAssistance = lazy(() => import(/* webpackChunkName: "TechnicalAssistance" */ '../pages/adminFockink/technicalAssistance'));
const TechnicalDeliveryDynamic = lazy(() => import(/* webpackChunkName: "TechnicalDeliveryDynamic" */ '../pages/adminFockink/TechnicalDeliveryDynamic/TechnicalDeliveryDynamic'));
const TechnicalDeliveryDynamicForm = lazy(() => import(/* webpackChunkName: "TechnicalDeliveryDynamicForm" */ '../pages/adminFockink/TechnicalDeliveryDynamic/TechnicalDeliveryDynamicForm'));
const TechnicalDeliveryDynamicNew = lazy(() => import(/* webpackChunkName: "TechnicalDeliveryDynamicNew" */ '../pages/adminFockink/TechnicalDeliveryDynamic/TechnicalDeliveryDynamicNew'));
const Administrator = lazy(() => import(/* webpackChunkName: "Administrator" */ '../pages/adminFockink/administrator/Administrator'));
const AdministratorForm = lazy(() => import(/* webpackChunkName: "AdministratorForm" */ '../pages/adminFockink/administrator/AdministratorForm'));
const AccountForm = lazy(() => import(/* webpackChunkName: "AccountForm" */ '../pages/adminFockink/organization/account/AccountForm'));
const AccountView = lazy(() => import(/* webpackChunkName: "AccountView" */ '../pages/adminFockink/organization/account/AccountView'));
const ThermoDeviceConf = lazy(() => import(/* webpackChunkName: "ThermoDeviceConf" */ '../pages/adminFockink/organization/account/plants/thermoDeviceConf/ThermoDeviceConf'));
const PlantsForm = lazy(() => import(/* webpackChunkName: "PlantsForm" */ '../pages/adminFockink/organization/account/plants/PlantsForm'));
const PlantsView = lazy(() => import(/* webpackChunkName: "PlantsView" */ '../pages/adminFockink/organization/account/plants/PlantsView'));
const EquipsForm = lazy(() => import(/* webpackChunkName: "EquipsForm" */ '../pages/adminFockink/organization/account/plants/equips/EquipsForm'));
const Comms = lazy(() => import(/* webpackChunkName: "Comms" */ '../pages/adminFockink/organization/account/plants/comms/Comms'));
const CommsForm = lazy(() => import(/* webpackChunkName: "CommsForm" */ '../pages/adminFockink/organization/account/plants/comms/CommsForm'));
const Users = lazy(() => import(/* webpackChunkName: "Users" */ '../pages/adminFockink/organization/account/users/Users'));
const UsersForm = lazy(() => import(/* webpackChunkName: "UsersForm" */ '../pages/adminFockink/organization/account/users/UsersForm'));
const IndustrialAutomationDash = lazy(() => import(/* webpackChunkName: "IndustrialAutomationDash" */ '../pages/adminClient/Dashes/IndustrialAutomationDash/IndustrialAutomationDash'));
const ThermometryDash = lazy(() => import(/* webpackChunkName: "ThermometryDash" */ '../pages/adminClient/Dashes/ThermometryDash/ThermometryDash'));
const ThermometrySettings = lazy(() => import(/* webpackChunkName: "ThermometrySettings" */ '../pages/adminClient/Dashes/ThermometryDash/Settings/ThermometrySettings'));
const ThermometryQualityReport = lazy(() => import(/* webpackChunkName: "ThermometryQualityReport" */ '../pages/adminClient/Reports/ThermoQuality/ThermometryQualityReport'));
const ThermometryReport = lazy(() => import(/* webpackChunkName: "ThermometryReport" */ '../pages/adminClient/Reports/ThermometryReport'));
const VariableTracking = lazy(() => import(/* webpackChunkName: "VariableTracking" */ '../pages/adminClient/Reports/VariableTracking'));
const Reports = lazy(() => import(/* webpackChunkName: "Reports" */ '../pages/adminClient/Reports/Reports'));
const ReportsAdm = lazy(() => import(/* webpackChunkName: "ReportsAdm" */ '../pages/adminClient/Reports/ReportsAdm'));
const IrrigationDash = lazy(() => import(/* webpackChunkName: "IrrigationDash" */ '../pages/adminClient/Dashes/IrrigationDash/IrrigationDash'));
const DryerDash = lazy(() => import(/* webpackChunkName: "DryerDash" */ '../pages/adminClient/Dashes/DryerDash/DryerDash'));
const DryerDashSettings = lazy(() => import(/* webpackChunkName: "DryerDashSettings" */ '../pages/adminClient/Dashes/DryerDash/DryerDashSettings/DryerDashSettings'));
const PhotovoltaicDash = lazy(() => import(/* webpackChunkName: "PhotovoltaicDash" */ '../pages/adminClient/Dashes/PhotovoltaicDash/PhotovoltaicDash'));
const PhotovoltaicSettings = lazy(() => import(/* webpackChunkName: "PhotovoltaicSettings" */ '../pages/adminClient/Dashes/PhotovoltaicDash/PhotovoltaicSettings'));
const Units = lazy(() => import(/* webpackChunkName: "Units" */ '../pages/settings/client/Units/Units'));
const UnitsForm = lazy(() => import(/* webpackChunkName: "UnitsForm" */ '../pages/settings/client/Units/UnitsForm'));
const PortalUsers = lazy(() => import(/* webpackChunkName: "PortalUsers" */ '../pages/settings/client/PortalUsers/PortalUsers'));
const EnergyCost = lazy(() => import(/* webpackChunkName: "EnergyCost" */ '../pages/settings/client/EnergyCost/EnergyCost'));
const PortalUsersForm = lazy(() => import(/* webpackChunkName: "PortalUsersForm" */ '../pages/settings/client/PortalUsers/PortalUsersForm'));
const EnergyCostForm = lazy(() => import(/* webpackChunkName: "EnergyCostForm" */ '../pages/settings/client/EnergyCost/EnergyCostForm'));
const Forbidden403 = lazy(() => import(/*: "Forbidden403" */ '../pages/auth/Forbidden403'));
const AuthByToken = lazy(() => import(/*: "AuthByToken" */ '../pages/auth/AuthByToken'));
const TechnicalDeliveryGenerator = lazy(() => import(/* webpackChunkName: "TechnicalDeliveryGenerator" */ '../pages/adminFockink/TechnicalDeliveryGenerator/index'));
const TechnicalDeliveryGeneratorList = lazy(() => import(/* webpackChunkName: "TechnicalDeliveryGeneratorList" */ '../pages/adminFockink/TechnicalDeliveryGenerator/TechnicalDeliveryGeneratorList'));
const IrrigationSettings = lazy(() => import(/* webpackChunkName: "IrrigationSettings" */ '../pages/adminClient/Dashes/IrrigationDash/IrrigationSettings'));
const AerationReport = lazy(() => import(/* webpackChunkName: "AerationReport" */ '../pages/adminClient/Reports/AerationReports'));
const PhotovoltaicReportEarning = lazy(() => import(/* webpackChunkName: "PhotovoltaicReportEarning" */ '../pages/adminClient/Reports/PhotovoltaicReportEarning'));
const IrrigationPlanning = lazy(() => import(/* webpackChunkName: "IrrigationPlanning" */ '../pages/adminClient/Reports/IrrigationPlanning/IrrigationPlanning'));
const CultureForm = lazy(() => import(/* webpackChunkName: "CultureForm" */ '../pages/settings/client/CultureForm'));
const TelemetrySettings = lazy(() => import(/* webpackChunkName: "TelemetrySettings" */ '../pages/settings/admin/TelemetrySettings'));
const TechDeliveryApprovers = lazy(() => import(/* webpackChunkName: "TechDeliveryApprovers" */ '../pages/settings/admin/TechDeliveryApprovers'));
const ThresholdConfig = lazy(() => import(/* webpackChunkName: "ThresholdConfig" */ '../pages/settings/client/ThresholdConfig'));
const IndustrialAutomationSettings = lazy(() => import(/* webpackChunkName: "IndustrialAutomationSettings" */ '../pages/adminClient/Dashes/IndustrialAutomationDash/IndustrialAutomationSettings'));
const PhotovoltaicMonitoring = lazy(() => import(/* webpackChunkName: "PhotovoltaicMonitoring" */ '../pages/adminFockink/PhotovoltaicMonitoring'));
const ClientDashAdm = lazy(() => import(/* webpackChunkName: "ClientDashAdm" */ '../pages/adminFockink/PlantsDash/ClientDashAdm'));
const PlantsAdm = lazy(() => import(/* webpackChunkName: "PlantsAdm" */ '../pages/adminFockink/PlantsDash/Plants/PlantsAdm'));
const EquipmentDashAdm = lazy(() => import(/* webpackChunkName: "EquipmentDashAdm" */ '../pages/adminFockink/EquipmentDash/EquipmentDashAdm'));
const EquipmentDashPlants = lazy(() => import(/* webpackChunkName: "EquipmentDashPlants" */ '../pages/adminFockink/PlantsDash/EquipmentDashPlants/EquipmentDashPlants'));
const Monitoring = lazy(() => import(/* webpackChunkName: "Monitoring" */ '../pages/adminFockink/Monitoring/index'));
const SoilAnalysis = lazy(() => import(/* webpackChunkName: "SoilAnalysis" */ '../pages/settings/admin/SoilAnalysis/index'));
const soilAnalysisList = lazy(() => import(/* webpackChunkName: "soilAnalysisList" */ '../pages/settings/admin/SoilAnalysis/SoilChemicalAnalysis/soilAnalysisList'));
const soilChemicalAnalysis = lazy(() => import(/* webpackChunkName: "soilChemicalAnalysis" */ '../pages/settings/admin/SoilAnalysis/SoilChemicalAnalysis/index'));
const SoilPhysicalAnalysis = lazy(() => import(/* webpackChunkName: "SoilPhysicalAnalysis" */ '../pages/settings/admin/SoilPhysicalAnalysis/SoilPhysicalAnalysis'));
const SoilPhysicalAnalysisForm = lazy(() => import(/* webpackChunkName: "SoilPhysicalAnalysisForm" */ '../pages/settings/admin/SoilPhysicalAnalysis/SoilPhysicalAnalysisForm'));
const SoilPhysicalAnalysisCl = lazy(() => import(/* webpackChunkName: "SoilPhysicalAnalysisCl" */ '../pages/settings/admin/SoilPhysicalAnalysis/index'));
const weatherStationReport = lazy(() => import(/* webpackChunkName: "weatherStationReport" */ '../pages/adminClient/Reports/WeatherStationReport/weatherStationReport'));
const DryerReport = lazy(() => import(/* webpackChunkName: "DryerReport" */ '../pages/adminClient/Reports/DryerReport/DryerReport'));
const ListCost = lazy(() => import(/* webpackChunkName: "ListCost" */ '../pages/adminFockink/CostManagement/ListCost'));
const CostManagement = lazy(() => import(/* webpackChunkName: "CostManagement" */ '../pages/adminFockink/CostManagement/CostManagement'));
const CostAdm = lazy(() => import(/* webpackChunkName: "CostAdm" */ '../pages/adminFockink/CostManagement/CostAdm'));
const FuelCostForm = lazy(() => import(/* webpackChunkName: "FuelCostForm" */ '../pages/settings/client/FuelCost/FuelCostForm'));
const FuelCostList = lazy(() => import(/* webpackChunkName: "FuelCostList" */ '../pages/settings/client/FuelCost/FuelCostList'));
const PlansForm = lazy(() => import(/* webpackChunkName: "PlansForm" */ '../pages/settings/client/Plans/PlansForm'));
const PlansList = lazy(() => import(/* webpackChunkName: "PlansList" */ '../pages/settings/client/Plans/PlansList'));
const CostProrated = lazy(() => import(/* webpackChunkName: "CostProrated" */ '../pages/adminFockink/ProratedCost/index'));
const CostService = lazy(() => import(/* webpackChunkName: "CostService" */ '../pages/adminFockink/CostManagement/CostService'));
const ConfigCost = lazy(() => import(/* webpackChunkName: "ConfigCost" */ '../pages/adminFockink/CostManagement/ConfigCost'));
const StorageCostForm = lazy(() => import(/* webpackChunkName: "StorageCostForm" */ '../pages/settings/admin/StorageCost/StorageCostForm'));
const CostAdmForm = lazy(() => import(/* webpackChunkName: "CostAdmForm" */ '../pages/adminFockink/CostManagement/CostAdmForm'));
const StorageCostList = lazy(() => import(/* webpackChunkName: "StorageCostList" */ '../pages/settings/admin/StorageCost/StorageCostList'));
const AzureCost = lazy(() => import(/* webpackChunkName: "AzureCost" */ '../pages/adminFockink/CostManagement/pages/AzureCost'));
const EnergyConsumption = lazy(() => import(/* webpackChunkName: "EnergyConsumption" */ '../pages/adminClient/Reports/EngineCost/EnergyConsumption'));
const ReportSettings = lazy(() => import(/* webpackChunkName: "ReportSettings" */ '../pages/settings/client/ReportSettings/reportSettings'));
const RepFormQualityReport = lazy(() => import(/* webpackChunkName: "RepFormQualityReport" */ '../pages/settings/client/ReportSettings/repFormQualityReport'));
const WheaterStationDash = lazy(() => import(/* webpackChunkName: "WheaterStationDash" */ '../pages/adminClient/Dashes/WeatherStation/WeatherStationDash'));
const WeatherStationSettings = lazy(() => import(/* webpackChunkName: "WeatherStationSettings" */ '../pages/adminClient/Dashes/WeatherStation/WeatherStationSettings'));
const ListMyFeedBacks = lazy(() => import(/* webpackChunkName: "ListMyFeedBacks" */ '../pages/ContactUs/Client/listMyFeedBacks'));
const EditFeedback = lazy(() => import(/* webpackChunkName: "EditFeedback" */ '../pages/ContactUs/Client/editFeedBack'));
const ListFeedBacks = lazy(() => import(/* webpackChunkName: "ListFeedBacks" */ '../pages/ContactUs/Admin/listFeedBacks'));
const AnswerFeedback = lazy(() => import(/* webpackChunkName: "AnswerFeedback" */ '../pages/ContactUs/Admin/answerFeedback'));
const DashboardConfig = lazy(() => import(/* webpackChunkName: "DashboardConfig" */ '../pages/settings/admin/Dashboard/DashboardConfig'));
const DashboardCad = lazy(() => import(/* webpackChunkName: "DashboardCad" */ '../pages/settings/admin/Dashboard/DashboardCad'));
const ReleaseNotesConfig = lazy(() => import(/* webpackChunkName: "ReleaseNotesConfig" */ '../pages/settings/admin/ReleaseNotes/ReleaseNotesConfig'));
const ReleaseNotesConfigNew = lazy(() => import(/* webpackChunkName: "ReleaseNotesConfigNew" */ '../pages/settings/admin/ReleaseNotes/ReleaseNotesEdit'));
const UserMonitoring = lazy(() => import(/* webpackChunkName: "UserMonitoring" */ '../pages/adminFockink/UserMonitoring/UserMonitoring'));
const AerationDetails = lazy(() => import(/* webpackChunkName: "AerationDetails" */ '../pages/adminClient/Reports/AerationDetails/AerationDetails'));
const AerationXWeatherReport = lazy(() => import(/* webpackChunkName: "AerationXWeatherReport" */ '../pages/adminClient/Reports/AerationXWeatherReport'));
const ThermometryMonitoring = lazy(() => import(/* webpackChunkName: "ThermometryMonitoring" */ '../pages/adminFockink/ThermometryMonitoring/ThermometryMonitoring'));
const SiloModels = lazy(() => import(/* webpackChunkName: "SiloModels" */ '../pages/adminFockink/organization/SiloModels/SiloModels'));
const CommunicatorsReport = lazy(() => import(/* webpackChunkName: "CommunicatorsReport" */ '../pages/adminClient/Reports/CommunicatorsReport/CommunicatorsReport'));
const SiloModelsCadEdit = lazy(() => import(/* webpackChunkName: "SiloModelsCadEdit" */ '../pages/adminFockink/organization/SiloModels/CadEdit/SiloModelsCadEdit'));
const IrrigationMonitoring = lazy(() => import(/* webpackChunkName: "IrrigationMonitoring" */ '../pages/adminFockink/IrrigationMonitoring/IrrigationMonitoring'));
const UserDecoupling = lazy(() => import(/* webpackChunkName: "UserDecoupling" */ '../pages/adminFockink/UserDecoupling'));
const NotFound404 = lazy(() => import(/* webpackChunkName: "NotFound404" */ '../pages/auth/NotFound404'));
const releases = lazy(() => import(/* webpackChunkName: "releases" */ '../pages/ReleaseNotes'));
const defaultAlarms = lazy(() => import(/* webpackChunkName: "defaultAlamrs" */ '../pages/adminFockink/DefaultAlarmRoles'));
const defaultAlarmsForm = lazy(() => import(/* webpackChunkName: "defaultAlarmsForm" */ '../pages/adminFockink/DefaultAlarmsForm'));
const thermoWeatherReport = lazy(() => import(/* webpackChunkName: "thermoWeatherReport" */'../pages/adminClient/Reports/ThermoWeatherReport'));
const temperatureTendency = lazy(() => import(/* webpackChunkName: "temperatureTendency" */ '../pages/adminClient/Reports/TemperatureTendency'));

const links = [
  {
    name: 'Início',
    icon: <MdDashboard size={25} />,
    path: '/',
    component: Home,
    isPrivate: true,
    showIn: ['all'],
  },
  {
    name: 'Cadastro de alarmes Padrões',
    path: '/settings/defaultAlarms/:id',
    component: defaultAlarmsForm,
  },
  {
    name: 'Alarmes Padrões',
    path: '/settings/defaultAlarms',
    component: defaultAlarms,
    isPrivate: true,
  },
  {
    name: 'Release Notes',
    path: '/releases',
    component: releases,
    isPrivate: false,
    showIn: ['all'],
  },
  {
    path: '/dashboards/plants',
    component: PlantsAdm,
    isPrivate: true,
  },
  {
    path: '/settings/soilAnalysis',
    component: SoilAnalysis,
    isPrivate: true,
  },
  {
    path: '/settings/soilAnalysis/:clid',
    component: soilAnalysisList,
    isPrivate: true,
  },
  {
    path: '/settings/soilAnalysis/:clid/:id',
    component: soilChemicalAnalysis,
    isPrivate: true,
  },
  {
    path: '/equipamentDataAdm',
    component: EquipmentDashAdm,
    isPrivate: true,
  },
  {
    path: '/equipamentDataPlants',
    component: EquipmentDashPlants,
    isPrivate: true,
  },
  {
    name: 'Clientes',
    icon: <MdAssignmentInd size={25} />,
    path: '/clientsAdm/',
    component: ClientDashAdm,
    isPrivate: true,
    showIn: ['admin', 'comercial'],
  },
  {
    name: 'Monitoramento',
    icon: <MdAssessment size={25} />,
    path: '/monitoring',
    component: Monitoring,
    isPrivate: true,
    showIn: ['admin', 'comercial'],
  },
  {
    name: 'Relatórios',
    icon: <MdTrendingUp size={25} />,
    path: '/reports',
    component: Reports,
    isPrivate: true,
    showIn: ['all'],
  },
  {
    name: 'Relatórios',
    icon: <MdTrendingUp size={25} />,
    path: '/reportsAdm',
    component: ReportsAdm,
    isPrivate: true,
  },
  {
    name: 'Relatório de Ganhos',
    path: '/reports/photolcoltaic-earnings',
    component: PhotovoltaicReportEarning,
    isPrivate: true,
  },
  {
    name: 'Relatório de Estação Meteorológica',
    path: '/reports/weatherStationReport',
    component: weatherStationReport,
    isPrivate: true,
  },
  {
    name: 'Relatório de Estação Meteorológica',
    path: '/reportsAdm/weatherStationReport',
    component: weatherStationReport,
    isPrivate: true,
  },
  {
    name: 'Relatório de Histórico de Operação do Secador',
    path: '/reports/dryerReport',
    component: DryerReport,
    isPrivate: true,
  },
  {
    path: '/settings/generateTechDelivery',
    component: TechnicalDeliveryGeneratorList,
    isPrivate: true,
  },
  {
    path: '/settings/generateTechDelivery/:id',
    component: TechnicalDeliveryGenerator,
    isPrivate: true,
  },
  {
    path: '/settings/generateTechDelivery/:id/:mode',
    component: TechnicalDeliveryGenerator,
    isPrivate: true,
  },
  {
    path: '/reports/temperatureTendency',
    component: temperatureTendency,
    isPrivate: true,
  },
  {
    path: '/reports/thermoWeather',
    component: thermoWeatherReport,
    isPrivate: true,
  },
  {
    name: 'Entrega Técnica',
    icon: <MdVerticalSplit size={25} />,
    path: '/dynamicTechDelivery',
    component: TechnicalDeliveryDynamic,
    isPrivate: true,
    showIn: ['admin'],
  },
  {
    name: 'Organizações',
    icon: <MdBusiness size={25} />,
    path: '/organization',
    component: Organization,
    isPrivate: true,
    showIn: ['admin'],
    permissionId: AdminPermissions.ORGANIZATIONS_VIEW.value,
  },
  {
    name: 'Organizações',
    icon: <MdBusiness size={25} />,
    path: '/organization/organizationCreate',
    component: OrganizationCreate,
    isPrivate: true,
    permissionId: AdminPermissions.ORGANIZATIONS_VIEW.value,
  },
  {
    name: 'Administradores',
    icon: <MdAssignmentInd size={25} />,
    path: '/administrator',
    component: Administrator,
    isPrivate: true,
    showIn: ['admin'],
    permissionId: AdminPermissions.ADMINSTRATORS_VIEW.value,
  },
  {
    name: 'Assistência Técnica',
    path: '/technicalAssistance',
    component: TechnicalAssistance,
    isPrivate: true,
    /* permissionId: AdminPermissions.ADMINSTRATORS_VIEW.value, */
  },
  {
    name: 'Monitoramento Fotovoltaica',
    path: '/photovoltaicMonitoring',
    component: PhotovoltaicMonitoring,
    isPrivate: true,
  },
  {
    name: 'Monitoramento de Usuários',
    path: '/userMonitoring',
    component: UserMonitoring,
    isPrivate: true,
  },
  {
    name: 'Monitoramento de Termometria',
    path: '/thermometryMonitoring',
    component: ThermometryMonitoring,
    isPrivate: true,
  },
  {
    name: 'Monitoramento de Irrigação',
    path: '/IrrigationMonitoring',
    component: IrrigationMonitoring,
  },
  {
    name: 'Desligamento de Colaboradores',
    path: '/reports/userDecoupling',
    component: UserDecoupling,
    isPrivate: true,
  },
  {
    name: 'Financeiro',
    path: '/listCost',
    icon: <MdLocalAtm size={25} />,
    component: ListCost,
    isPrivate: true,
    showIn: ['admin'],
  },
  {
    name: 'Configurações',
    icon: <MdSettings size={25} />,
    path: '/settings',
    component: Settings,
    isPrivate: true,
    showIn: ['admin', 'clientadmin'],
  },
  {
    name: 'Login',
    icon: <MdPerson size={25} />,
    path: '/login',
    component: Login,
    showIn: ['all'],
    noMenu: true,
  },
  {
    path: '/logout',
    component: Logout,
    isPrivate: true,
    noMenu: true,
  },
  {
    path: '/authByToken/:source/:token',
    component: AuthByToken,
    isPrivate: false,
  },
  {
    path: '/resetPassword',
    component: ResetPassword,
    isPrivate: false,
    noMenu: true,
  },
  {
    path: '/culture/new',
    component: CultureForm,
    isPrivate: true,
  },
  {
    path: '/variaveis/config',
    component: ThresholdConfig,
    isPrivate: true,
  },
  {
    path: '/settings/telemetry',
    component: TelemetrySettings,
    isPrivate: true,
  },
  {
    path: '/settings/tdApprovers',
    component: TechDeliveryApprovers,
    isPrivate: true,
  },
  {
    path: '/profile',
    component: Profile,
    isPrivate: true,
  },
  {
    path: '/portalUsers/:id',
    component: PortalUsersForm,
    isPrivate: true,
    /* permissionId: PortalPermissions.USERS_VIEW.value, */
  },
  {
    path: '/energyCost/:id',
    component: EnergyCostForm,
    isPrivate: true,
    /* permissionId: PortalPermissions.ENERGY_COST_MANAGE.value, */
  },
  {
    path: '/units/:id',
    component: UnitsForm,
    isPrivate: true,
    /* permissionId: PortalPermissions.UNITS_MANAGE.value, */
  },
  {
    path: '/organization/:id',
    component: OrganizationForm,
    isPrivate: true,
    permissionId: AdminPermissions.ORGANIZATIONS_VIEW.value,
  },
  {
    path: '/organization/:id/siloModels',
    component: SiloModels,
    isPrivate: true,
    permissionId: AdminPermissions.ORGANIZATIONS_VIEW.value,
  },
  {
    path: '/organization/:id/siloModels/:modelId',
    component: SiloModelsCadEdit,
    isPrivate: true,
    permissionId: AdminPermissions.ORGANIZATIONS_VIEW.value,
  },
  {
    path: '/organization/:orgId/account/:id',
    component: AccountForm,
    isPrivate: true,
    permissionId: AdminPermissions.ACCOUNTS_MANAGE.value,
  },
  {
    path: '/organization/:orgId/account/:id/view',
    component: AccountView,
    isPrivate: true,
    permissionId: AdminPermissions.ACCOUNTS_VIEW.value,
  },
  {
    path:
      '/organization/:orgId/account/:accountId/plant/:plantId/thermoDeviceConf',
    component: ThermoDeviceConf,
    isPrivate: true,
    // permissionId: AdminPermissions.PLANTS_MANAGE.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:id',
    component: PlantsForm,
    isPrivate: true,
    // permissionId: AdminPermissions.PLANTS_MANAGE.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:id/view',
    component: PlantsView,
    isPrivate: true,
    permissionId: AdminPermissions.PLANTS_VIEW.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:plId/equip/:id',
    component: EquipsForm,
    isPrivate: true,
    permissionId: AdminPermissions.EQUIPAMENTS_MANAGE.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:plId/comm',
    component: Comms,
    isPrivate: true,
    permissionId: AdminPermissions.COMMUNICATORS_VIEW.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:plId/comm/:id',
    component: CommsForm,
    isPrivate: true,
    permissionId: AdminPermissions.COMMUNICATORS_MANAGE.value,
  },
  {
    path: '/organization/:orgId/account/:accId/user',
    component: Users,
    isPrivate: true,
    permissionId: AdminPermissions.USERS_VIEW.value,
  },
  {
    path: '/organization/:orgId/account/:accId/user/:id',
    component: UsersForm,
    isPrivate: true,
    permissionId: AdminPermissions.USERS_VIEW.value,
  },
  {
    path: '/administrator/:id',
    component: AdministratorForm,
    isPrivate: true,
    permissionId: AdminPermissions.ADMINSTRATORS_VIEW.value,
  },
  {
    path: '/photovoltaicDash/:id',
    component: PhotovoltaicDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.PHOTOVOLTAIC_DASHBOARD_VIEW.value, */
  },
  {
    path: '/photovoltaicDash/:id/settings',
    component: PhotovoltaicSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.PHOTOVOLTAIC_SETTINGS_VIEW.value, */
  },
  {
    path: '/automationDash/:id',
    component: IndustrialAutomationDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.AUTOMATION_DASHBOARD_VIEW.value, */
  },
  {
    path: '/automationDash/:id/settings',
    component: IndustrialAutomationSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.AUTOMATION_DASHBOARD_VIEW.value, */
  },
  {
    path: '/thermometryDash/:id',
    component: ThermometryDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_DASHBOARD_VIEW.value, */
  },
  {
    path: '/thermometryDash/:id/settings',
    component: ThermometrySettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/irrigationDash/:id',
    component: IrrigationDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.IRRIGATION_DASHBOARD_VIEW.value, */
  },
  {
    path: '/irrigationDash/:id/settings',
    component: IrrigationSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/dryerDash/:id',
    component: DryerDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.IRRIGATION_DASHBOARD_VIEW.value, */
  },
  {
    path: '/dryerDash/:id/settings',
    component: DryerDashSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/reports/irrigation-planning',
    component: IrrigationPlanning,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/reportsAdm/irrigation-planning',
    component: IrrigationPlanning,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/dynamicTechDelivery/new',
    component: TechnicalDeliveryDynamicNew,
    isPrivate: true,
  },
  {
    path: '/dynamicTechDelivery/:id/:tdbId',
    component: TechnicalDeliveryDynamicForm,
    isPrivate: true,
  },
  {
    path: '/dynamicTechDelivery/:id',
    component: TechnicalDeliveryDynamicForm,
    isPrivate: true,
  },
  {
    path: '/reports/thermoquality',
    component: ThermometryQualityReport,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value, */
  },
  {
    path: '/reportsAdm/thermoquality',
    component: ThermometryQualityReport,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value, */
  },
  {
    path: '/reports/thermometry',
    component: ThermometryReport,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value, */
  },
  {
    path: '/reports/aeration',
    component: AerationReport,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value, */
  },
  {
    path: '/reports/aerationDetails',
    component: AerationDetails,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value, */
  },
  {
    path: '/reports/aerationweather',
    component: AerationXWeatherReport,
    isPrivate: true,
  },
  {
    path: '/reports/variableTracking',
    component: VariableTracking,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reports/CommunicatorsReport',
    component: CommunicatorsReport,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reportsAdm/engineCost',
    component: EnergyConsumption,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reportsAdm/photolcoltaic-earnings',
    component: PhotovoltaicReportEarning,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reportsAdm/variableTracking',
    component: VariableTracking,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reportsAdm/thermometry',
    component: ThermometryReport,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reportsAdm/aeration',
    component: AerationReport,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/energyCost',
    component: EnergyCost,
    isPrivate: true,
    /* permissionId: PortalPermissions.ENERGY_COST_MANAGE.value, */
  },
  {
    path: '/units',
    component: Units,
    isPrivate: true,
    /* permissionId: PortalPermissions.UNITS_VIEW.value, */
  },
  {
    path: '/portalUsers',
    component: PortalUsers,
    isPrivate: true,
    /* permissionId: PortalPermissions.USERS_VIEW.value, */
  },
  {
    path: '/settings/blockedHosts',
    component: BlockedHosts,
    isPrivate: true,
  },
  {
    path: '/settings/techDeliveryTypes',
    component: TechDeliveryTypes,
    isPrivate: true,
  },
  {
    path: '/403',
    component: Forbidden403,
    isPrivate: true,
  },
  {
    path: '/settings/soilPhysicalAnalysis',
    component: SoilPhysicalAnalysisCl,
    isPrivate: true,
  },
  {
    path: '/settings/soilPhysicalAnalysis/:clid',
    component: SoilPhysicalAnalysis,
    isPrivate: true,
  },
  {
    path: '/settings/soilPhysicalAnalysis/:clid/:id',
    component: SoilPhysicalAnalysisForm,
    isPrivate: true,
  },
  {
    path: '/costManagement/:id',
    component: CostManagement,
    isPrivate: true,
  },
  {
    path: '/costManagement/:id/azureCost',
    component: AzureCost,
    isPrivate: true,
  },
  {
    path: '/settings/costFuel/:id',
    component: FuelCostForm,
    isPrivate: true,
  },
  {
    path: '/settings/costFuel',
    component: FuelCostList,
    isPrivate: true,
  },
  {
    path: '/settings/plans/:id',
    component: PlansForm,
    isPrivate: true,
  },
  {
    path: '/settings/plans',
    component: PlansList,
    isPrivate: true,
  },
  {
    path: '/ListCost/ConfigCost/CostProrated',
    component: CostProrated,
    isPrivate: true,
  },
  {
    path: '/ListCost/ConfigCost/CostService',
    component: CostService,
    isPrivate: true,
  },
  {
    path: '/ListCost/ConfigCost/contractedService',
    component: CostAdm,
    isPrivate: true,
  },
  {
    path: '/ListCost/ConfigCost',
    component: ConfigCost,
    isPrivate: true,
  },
  {
    path: '/ListCost/ConfigCost/costManagement',
    component: StorageCostList,
    isPrivate: true,
  },
  {
    path: '/ListCost/ConfigCost/costManagement/:id',
    component: StorageCostForm,
    isPrivate: true,
  },
  {
    path: '/ListCost/ConfigCost/contractedService/:id',
    component: CostAdmForm,
    isPrivate: true,
  },
  {
    path: '/reports/engineCost',
    component: EnergyConsumption,
    isPrivate: true,
  },
  {
    path: '/weatherStation/:id',
    component: WheaterStationDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_DASHBOARD_VIEW.value, */
  },
  {
    path: '/weatherStation/:id/settings',
    component: WeatherStationSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/weatherStationPlant/:id',
    component: WeatherStationSingleDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_DASHBOARD_VIEW.value, */
  },
  {
    path: '/weatherStationPlant/:id/settings',
    component: WeatherStationSingleSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/settings/reportSettings',
    component: ReportSettings,
    isPrivate: true,
  },
  {
    path: '/settings/reportSettings/qualityReport',
    component: RepFormQualityReport,
    isPrivate: true,
  },
  {
    path: '/settings/dashboardsCustomize',
    component: DashboardConfig,
    isPrivate: true,
  },
  {
    path: '/settings/ReleaseNotesConfig',
    component: ReleaseNotesConfig,
    isPrivate: true,
  },
  {
    path: '/settings/ReleaseNotesConfig/:id',
    component: ReleaseNotesConfigNew,
    isPrivate: true,
  },
  {
    path: '/settings/dashboardsCustomize/:id',
    component: DashboardCad,
    isPrivate: true,
  },
  {
    name: 'Fale Conosco',
    icon: <MdQuestionAnswer size={25} />,
    path: '/myfeedbacks',
    component: ListMyFeedBacks,
    isPrivate: true,
    showIn: ['clientadmin'],
  },
  {
    path: '/myfeedbacks/edit/:id',
    component: EditFeedback,
    isPrivate: true,
  },
  {
    name: 'Fale Conosco',
    icon: <MdQuestionAnswer size={25} />,
    path: '/feedbacks',
    component: ListFeedBacks,
    isPrivate: true,
    showIn: ['admin'],
  },
  {
    path: '/feedbacks/answer/:id',
    component: AnswerFeedback,
    isPrivate: true,
  },
  {
    path: '*',
    component: NotFound404,
    isPrivate: true,
  },
];

const findByLink = (link) => {
  const replaceLink = link.replace(
    /([0-9a-f]{8})\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b([0-9a-f]{12})/,
    ':id',
  );
  const route = links.find(
    (f) => f.path === `${replaceLink.startsWith('/') ? '' : '/'}${replaceLink}`,
  );

  if (route) {
    route.to = replaceLink;
  }

  return route;
};

const navbarLinks = (token) => {
  if (token) {
    let profileType;
    token = jwtDecode(token);
    const p = token.permissions ? token.permissions : [];
    if (token.profile.profileId === 14) {
      profileType = 'comercial';
    } else if (token.profile.profileId >= 10 && token.profile.profileId <= 13) {
      profileType = 'admin';
    } else if (token.profile.profileId >= 51 && token.profile.profileId <= 54) {
      profileType = 'clientadmin';
    }
    const filter = links.filter((f) => {
      if (
        !token &&
        !f.isPrivate &&
        !f.noMenu &&
        ((f.showIn || [])[0] === 'all' ||
          (f.showIn || []).indexOf(profileType) > -1)
      ) {
        return true;
      }
      if (
        token &&
        f.isPrivate &&
        !f.noMenu &&
        ((f.showIn || [])[0] === 'all' ||
          (f.showIn || []).indexOf(profileType) > -1)
      ) {
        if (f.permissionId) {
          if (p.indexOf(f.permissionId) > -1 || f.permissionId === 100) {
            return true;
          }
          return false;
        }
        return true;
      }
      return false;
    });
    return filter;
  }
};

const routerLinks = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      {links.map((e) => {
        if (e.isPrivate) {
          return (
            <PR.PrivateRoute
              exact
              path={e.path}
              permissionId={e.permissionId}
              component={e.component}
              key={e.path}
            />
          );
        }
        return <Route path={e.path} component={e.component} key={e.path} />;
      })}
    </Switch>
  </Suspense>
);

export default {
  findByLink,
  navbarLinks,
  routerLinks,
};
