/* eslint-disable import/prefer-default-export */

const hasPermission = (pid) => {
  if (pid) {
    const p = JSON.parse(localStorage.permissions || '[]');
    return p.indexOf(pid) > -1;
  }
  return true;
};

export {
  hasPermission,
};
