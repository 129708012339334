import React from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { handleMessageError } from '../helpers/handlerMessageError';
import FockinkAPI from '../middlewares/Api';

const AlarmsContext = React.createContext(null);

export function AlarmProvider({ children }) {
  const [alarmRules, setAlarmRules] = React.useState([]);
  const [alarmToEdit, setAlarmToEdit] = React.useState();

  const MySwal = withReactContent(Swal);

  const selectAlarmToEdit = (selectedAlarm) => {
    setAlarmToEdit(selectedAlarm);
  };

  const handleClearAlarmToUpdate = () => setAlarmToEdit();

  const handleGetAlarms = async () => {
    try {
      const response = await FockinkAPI.get('/alertRules');
      const { data } = response.data;

      setAlarmRules(
        data.sort((a, b) => {
          if (a.status.toLowerCase() < b.status.toLowerCase()) {
            return -1;
          }
          if (a.status.toLowerCase() > b.status.toLowerCase()) {
            return 1;
          }

          return 0;
        }),
      );
    } catch (error) {
      handleMessageError(error);
    }
  };

  const handleDisableAlarm = async (values) => {
    try {
      values.status = 'I';
      const copyValuesData = { ...values };
      delete copyValuesData.id;

      MySwal.fire({
        title: 'Confirmar Desabilitar o Alarme?',
        text: 'O Alarme será desabilidato permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Desabilitar Alarme',
        cancelButtonText: 'Cancelar Ação',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await FockinkAPI.put(`/alertRules/${values.id}`, copyValuesData);
          toast.success('Alarme desabilitado');
          handleGetAlarms();
        }
      });
    } catch (error) {
      handleMessageError(error);
    }
  };

  const handleUpdateAlarm = async (values) => {
    try {
      const valuesToUpdate = { ...values };
      delete valuesToUpdate.id;
      if (
        !valuesToUpdate.actions.emails ||
        valuesToUpdate.actions.emails.length === 0
      ) {
        valuesToUpdate.actions.emails = [];
      }
      if (
        !valuesToUpdate.actions.sms ||
        valuesToUpdate.actions.sms.length === 0
      ) {
        valuesToUpdate.actions.sms = [];
      }
      if (
        !valuesToUpdate.actions.whatsapp ||
        valuesToUpdate.actions.whatsapp.length === 0
      ) {
        valuesToUpdate.actions.whatsapp = [];
      }
      await FockinkAPI.put(`/alertRules/${values.id}`, valuesToUpdate);
      handleGetAlarms();
      toast.success('Os dados do Alarme foram atualizados');
    } catch (error) {
      handleMessageError(error);
    }
  };

  const handleAddNewAlarmRule = async (values) => {
    try {
      const copyValues = { ...values };
      if (
        !copyValues.actions.emails ||
        copyValues.actions.emails.length === 0
      ) {
        copyValues.actions.emails = [];
      }
      if (!copyValues.actions.sms || copyValues.actions.sms.length === 0) {
        copyValues.actions.sms = [];
      }
      if (
        !copyValues.actions.whatsapp ||
        copyValues.actions.whatsapp.length === 0
      ) {
        copyValues.actions.whatsapp = [];
      }

      await FockinkAPI.post('/alertRules', copyValues);
      handleGetAlarms();
      toast.success('Alarme criado com sucesso');
    } catch (error) {
      handleMessageError(error);
    }
  };

  return (
    <AlarmsContext.Provider
      value={{
        alarmRules,
        alarmToEdit,
        handleGetAlarms,
        handleAddNewAlarmRule,
        handleDisableAlarm,
        selectAlarmToEdit,
        handleClearAlarmToUpdate,
        handleUpdateAlarm,
      }}
    >
      {children}
    </AlarmsContext.Provider>
  );
}

export function useAlarmsContext() {
  const context = React.useContext(AlarmsContext);
  if (!context) {
    throw new Error('Alarm context must be inside context API');
  }

  return context;
}
